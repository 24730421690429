let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let url = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let statsDbName = 'stats';

if (env === 'test') {
	url = 'https://cgl-retssalsspil-test.web.app';
	apiURL = 'https://cgl-retssalsspil-test.web.app/api/';	
}

if (env === 'demo') {
	url = 'https://cgl-retssalsspil-demo.web.app';
	apiURL = 'https://cgl-retssalsspil-demo.web.app/api/';
	statsDbName = 'stats-demo';	
}


if (env === 'production') {
	url = 'https://cgl-retssalsspil-production.web.app';
	apiURL = 'https://cgl-retssalsspil-production.web.app/api/';
}


const appConfig = {
	env: env,
	name: 'Domsmandsspillet',
	url: url,
	apiURL: apiURL,
	statsDbName,
	cookiesAcceptedCookieName: 'retssalsspil_crypt_cookies_accepted',
	playerDataCookieName: 'retssalsspil_crypt_player_data',
	availabilityStatementLink: 'https://www.was.digst.dk/domsmandsspillet-dk',
};

export default appConfig;
