const defaultPlayerData = {
    statsDocId: null,
    created: Math.floor(Date.now() / 1000),
    completed: false,
    questionsAsked: [],
    verdict: null,
    showChoices: false,
    punishment: null,
    id: 'player1',
    courtRoomComplete: [],
    votingRoomComplete: [],
    isStarted: false,
    isLegalFrameworkComplete: false,
    isArrivalComplete: false,
    isPreCourtComplete: false,
    otherVerdict: null,
};

export default defaultPlayerData;
