import React from 'react';
import PropTypes from 'prop-types';
import './carousel.scss';

export const CarouselItem = ({children}) => {
	return (
		<div className="Carousel-item" style={{width: '100%'}}>
			{children}
		</div>
	);
};

const Carousel = ({children, activeIndex, goToTextIndex, amountOfTexts, isNextText}) => {
	return (
		<div className="Carousel">
			<div
				className="Carousel-inner"
				style={{ transform: `translateX(-${activeIndex * 100}%) ` }}
			>
				{React.Children.map(children, (child) => {
					return React.cloneElement(child);
				})}
			</div>
			<div className="Carousel-indicators">
				<div className="Carousel-indicatorWrap">
					{children.length > 1 && activeIndex !== 0 &&
					<div className="Carousel-indicator prev" onClick={() => {goToTextIndex(activeIndex - 1);}}/>}
				</div>
				{children.length > 1 && <div className="Carousel-status"> 
					{(children.findIndex((_, index) => {return index === activeIndex;}) + 1)} / {children.length}
				</div>}
				<div className="Carousel-indicatorWrap">
					{children.length > 1 && activeIndex !== amountOfTexts - 1 &&
					<div className={`Carousel-indicator next ${isNextText ? 'blink' : ''}`} 
						onClick={() => {goToTextIndex(activeIndex + 1);}}/>}
				</div>
			</div>
		</div>
	);
};

CarouselItem.propTypes = {
	children: PropTypes.any,
};

Carousel.propTypes = {
	children: PropTypes.any,
	goToTextIndex: PropTypes.func.isRequired,
	activeIndex: PropTypes.number.isRequired,
	amountOfTexts: PropTypes.number.isRequired,
	isNextText: PropTypes.bool,
};

export default Carousel;