import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import renderMarkdown from 'helpers/text-helper';
import {arrivalUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import Carousel, {CarouselItem} from 'components/carousel/carousel';
import './arrival.scss';

const Arrival = (props) => {
	const {
		showButtonAnimation,
		textIndex,
		playerData,
		updatePlayerData,
		playClick,
		goToTextIndex,
		goToPage,
		setShowButtonAnimation,
	} = props;
	
	const gif = useRef(null);
	const canContinue = (playerData.isArrivalComplete ? true : false);

	/**
	 * Component did mount
	 */
	useEffect(()=>{
		/* Start bird gif */
		if (gif.current) {
			gif.current.src = require('assets/videos/city-court-with-birds.gif').default;
		}

		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Mark arrival as completed on mount
	 */
	useEffect(() => {
		updatePlayerData({isArrivalComplete: true});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'Arrival'}>
			<img ref={gif} className='Arrival-video' alt='birds animation'
				src={arrivalUiTexts.video}>
			</img>

			<div className={'Arrival-body body-' + textIndex}>
				{<div className={'Arrival-textbox' }>
					<Carousel goToTextIndex={goToTextIndex} activeIndex={textIndex} 
						amountOfTexts={arrivalUiTexts.texts.length}
						isNextText={showButtonAnimation}>
						{arrivalUiTexts.texts.map((text, index) => {
							return <CarouselItem key={index}>
								<div className={'Arrival-text'}>{text ? renderMarkdown(text) : ''}</div>
							</CarouselItem>;
						})}
					</Carousel>
				</div>}
			</div>

			<Button 
				classes={['prev']} 
				onClick={() => {
					gif.current.src = '';
					playClick();
					goToPage('intro');
				}} 
			/>
			<Button classes={['next ' + (!canContinue ? 'disabled' : '')]} onClick={() => {
				if (!canContinue) {
					setShowButtonAnimation(true);
					return;
				}
				playClick();
				goToPage('pre-court');
			}} />
		</div>
	);
};

Arrival.propTypes = {
	showButtonAnimation: PropTypes.bool.isRequired,
	textIndex: PropTypes.number.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	goToTextIndex: PropTypes.func.isRequired,
	playClick: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
	setShowButtonAnimation: PropTypes.func.isRequired,
};

export default Arrival;