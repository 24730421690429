import React, {Component} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getCookie, setCookie, deleteCookie} from 'helpers/cookie-helper';
import {getRoomAndAnimation} from 'helpers/game-helper';
import defaultPlayerData from 'data/player-data';
import {introUiTexts, preCourtUiTexts} from 'data/ui-texts';
import {popupData, courtRoomOrder, votingRoomOrder } from 'data/popup-data';
import {getNavData} from 'helpers/game-helper';
import Button from 'components/button/button';
import Intro from 'components/intro/intro';
import Arrival from 'components/arrival/arrival';
import PreCourt from 'components/pre-court/pre-court';
import CourtRoom from 'components/court-room/court-room';
import Departure from 'components/departure/departure';
import Outro from 'components/outro/outro';
import GameAudio from 'components/game-audio/game-audio';
import Orientation from 'components/orientation/orientation';
import clickAudio from 'assets/audio/click.wav';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';

class GameController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStartingGame: false,
			isPaused: false,
			isStopped: false,
			showRoomAnimation: true,
			showButtonAnimation: false,
			showPopupVerdict: false,
			showPopupTip: false,
			showLegalFrameWork: false,
			page: 'intro',
			textIndex: 0,
			popupIndex: 1,
			prevPopupIndex: 0,
			tabId: 1,
			prevTabId: 0,
			playerData: null,
			popup: {show: true, type: 'welcome'},
			cookieConsent: false,
			hasAnsweredConsent: false,
			triggerFirstTimeConsent: true,
		};
	}

	/**
	 * Load player data from either cookie or default data
	 */
	componentDidMount = () => {
		/* Use cached playerData if it exists */
		getCookie(appConfig.playerDataCookieName).then((response) => {
			/* Load player data if available */
			let playerData = JSON.parse(JSON.stringify(defaultPlayerData));

			if (response && response.status !== 'fail') {
				playerData = Object.assign({}, {...playerData, ...JSON.parse(response.data)});
			}
			/* Update state */
			this.setState({
				hasAnsweredConsent: response.status !== 'fail',
				cookieConsent: response.status !== 'fail',
				isLoading: false, 
				playerData
			});
		});
	}

	/**
	 * Reset player data
	 */
	resetPlayerData = (reload = false) => {
		deleteCookie(appConfig.cookiesAcceptedCookieName);
		deleteCookie(appConfig.playerDataCookieName);
		if (reload) {
			window.location.reload();
		}
		
	}

	/**
	 * Update player data
	 * @param {object} updates
	 */
	updatePlayerData = (updates) => {
		return new Promise((resolve) => {
			const newPlayerDataObj = Object.assign({}, {...this.state.playerData, ...updates});
			setCookie(appConfig.playerDataCookieName, JSON.stringify(newPlayerDataObj));
			this.setState({playerData: newPlayerDataObj}, () => {
				resolve({status: 'ok'});
			});
		});
	};

	/**
	 * Start game
	 */
	startGame = () => {
		this.setState({isStartingGame: true}, ()=>{
			/* Check if OK to cookies return if not */
			if (!this.state.cookieConsent) {
				return;
			}

			/* Start game */
			if (this.state.playerData && !this.state.playerData.statsDocId) {
				/* New game: Add new document to stats collection */
				const currentDate = dayjs(new Date()).format('YYYYMMDD');
				apiHelper('user/create-stats-doc', {
					created: currentDate,
					status: 'started',
				}).then(
					(response)=>{
						this.updatePlayerData(
							{statsDocId: response.statsDocId, isStarted: true}
						).then((response) =>{
							if (response.status === 'ok') {
								this.setState({isStartingGame: false}, () => {
									this.goToPage('arrival');
									if (this.props.handleFullscreen) this.props.handleFullscreen();
								});
							} 
						},
						(error) => {
							console.error(error);
						});
					},
					(error) => {
						console.error(error);
					}
				);
			} else {
				/* Continue game */
				this.setState({isStartingGame: false}, () => {
					this.goToPage('arrival');
					if (this.props.handleFullscreen) this.props.handleFullscreen();
				});
			}
		});
	}

	/**
	 * Update statistics in DB
	 * @param {object} updates
	 */
	updateStatsDoc = (updates) => {
		apiHelper('user/update-stats-doc', {
			statsDocId: this.state.playerData.statsDocId,
			updates,
		}).catch((error) => {
			console.error(error);
		});
	}

	/**
	 * Get statistics data
	 * @returns stats
	 */
	getStatsCollection = () => {
		return new Promise((resolve) => {
			apiHelper('user/get-stats-collection').then((response)=>{
		
				resolve({status: 'ok', stats: response.stats});
			}).catch((error) => {
				console.error(error);
			});
			
		});
	}

	/**
	 * Plays the click sound
	 */
	playClick = () => {
		if (this.state.isMuted) return;
		new Audio(clickAudio).play();
	};

	/**
	 * Go to a new page
	 * @param {string} page
	 */
	goToPage = (page, textIndex = 0, tabId = 1, popupIndex = 1, prevPopupIndex = 0, prevTabId = 0) => {
		const popup = (page === 'court-room' && this.state.page !== 'court-room' 
			? {show: true, type: 'welcome'}
			: this.state.popup
		);
		this.setState({
			page,
			textIndex,
			tabId,
			popupIndex,
			prevPopupIndex,
			prevTabId,
			popup
		});
	}

	/**
	 * Update popup data
	 * @param {object} popup 
	 */
	setPopup = (popup) => {
		this.setState({popup});
	};

	/**
	 * Update popup and prev popup indices
	 * @param {number} popupIndex 
	 * @param {number} prevPopupIndex 
	 */
	setPopupIndex = (popupIndex, prevPopupIndex) => {
		this.setState({
			popupIndex, 
			prevPopupIndex: (prevPopupIndex >= 0 ? prevPopupIndex : Math.max(popupIndex - 1, 0))
		});
	}

	/**
	 * Update tabId and prev tabId
	 * @param {number} tabId 
	 * @param {number} prevTabId 
	 */
	setTabId = (tabId, prevTabId) => {
		this.setState({
			tabId, 
			prevTabId: (prevTabId >= 0 ? prevTabId : Math.max(tabId - 1, 0))
		});
	}

	/**
	 * Toggle leagal framework
	 * @param {bool} show 
	 */
	setShowLegalFrameWork = (show) => {
		this.setState({showLegalFrameWork: show});
	}

	/**
	 * Toggle animate button
	 * @param {bool} show 
	 */
	setShowButtonAnimation = (show) => {
		this.setState({showButtonAnimation: show}, () => {
			if (show) {
				/* Auto-stop animation */
				setTimeout(() => {
					this.setShowButtonAnimation(false);
				}, 3500);
			}
		});
	};

	/**
	 * Go to text index
	 * @param {number} index 
	 */
	goToTextIndex = (index) => {
		/* Index can not be < 0 */
		let nextIndex = Math.max(index, 0);

		if (this.state.popup && this.state.popup.type !== 'empty') {
			/* Check max index value */
			const texts = (this.state.page === 'pre-court'
				? [preCourtUiTexts.preCourt.questions[0].question, null]
				: popupData[this.state.popup.type].texts
			);
			const tabs = popupData[this.state.popup.type].tabs;
			const numberOfTexts = texts.filter((text) => {
				return tabs ? text.tabId === this.state.tabId : true;
			}).length;
			nextIndex = Math.min(index, numberOfTexts - 1);
		}

		this.setState({
			textIndex: nextIndex
		});
	}

	/**
	 * Navigate to new page / navigate between popups
	 */
	handleNavigate = (newPopupIndex, page = null) => {	
		/* Reset popups */
		this.setState({
			showPopupVerdict: false,
			showLegalFrameWork: false,
			showTip: false,
			popup: {show: false, type: 'empty'}
		}, () => {
			/* Get order of popups */
			const popupsOrder = JSON.parse(JSON.stringify(courtRoomOrder.concat(votingRoomOrder)));

			
			let newPage = null;
			if (newPopupIndex === 0) newPage = (page ? page : 'pre-court');
			if (newPopupIndex >= popupsOrder.length) newPage = 'departure-room';
			if (newPage) {
				/* New page */
				const popup = {show: true, type: 'welcome'};
				this.setState({
					page: newPage,
					textIndex: 0,
					tabIndex: 1,
					popupIndex: 1,
					prevPopupIndex: 0,
					prevTabId: 0,
					popup
				});
			} else {
				/* Same page (court-room), new popup */
				const popupData = getNavData(newPopupIndex, this.state.popupIndex, this.state.playerData);
				if (popupData.completedRoomId) {
					this.completeRoom(popupData.completedRoomId);
				}
				this.setState({
					popupIndex: popupData.newPopupIndex,
					prevPopupIndex: popupData.prevPopupIndex,
					popup: (popupData.popupData ? popupData.popupData : {show: false, type: 'empty'}),
					showLegalFrameWork: popupData.showLegalFramework,
					showPopupTip: popupData.showTip,
					showPopupVerdict: popupData.showVerdict,
					showRoomAnimation: popupData.showAnimation
				}, () => {
					if (popupData.showTip) {
						/* Auto-close tip */
						setTimeout(() => {
							this.setState({showPopupTip: false});
						}, 2000);
					}
				});
			}
		});
	};

	/**
	 * When default popup is open and next button is clicked
	 */
	nextButtonClick = (textQuestionAndAudioData) => {
		let newSelectedTabId = this.state.tabId;
		let newPrevTabId = this.state.prevTabId;
		let animateBtn = false;

		const tabs = popupData[this.state.popup.type];

		if (textQuestionAndAudioData.availableQuestions.length !== 0 && textQuestionAndAudioData.question === null) {
			newPrevTabId = this.state.prevTabId - 1;
			animateBtn = true;
		} else if (
			this.state.playerData.questionsAsked.includes(textQuestionAndAudioData.question) && 
			textQuestionAndAudioData.question
		) {
			newPrevTabId = this.state.tabId;
			newSelectedTabId = this.state.tabId + 1;
		} else {
			newSelectedTabId = (this.state.tabId === tabs.length ? this.state.tabId : this.state.tabId + 1);
			if (this.state.tabId === tabs.length) {
				newPrevTabId = this.state.tabId;
			} else {
				newPrevTabId = this.state.tabId + 1;
			}
		}

		this.setState({
			tabId: newSelectedTabId,
			prevTabId: newPrevTabId,
			showAnimateButton: animateBtn
		});
	};

	/**
	 * Complete room
	 * @param {string} roomId 
	 */
	completeRoom = (roomId) => {
		let newCompletedRooms = JSON.parse(JSON.stringify(this.state.playerData.courtRoomComplete));
		newCompletedRooms.push(roomId);
		this.updatePlayerData({courtRoomComplete: newCompletedRooms});
	}

	/** Toggle audio paused */
	toggleIsPaused = (isPaused = null, isStopped = null) => {
		const newPausedState = (isPaused !== null ? isPaused : !this.state.isPaused);
		const newStoppedState = (isStopped !== null ? isStopped : this.state.isStopped);
		this.setState({isPaused: newPausedState, isStopped: newStoppedState});
	}


	/**
	 * Accepts or declines cookies depending on the given boolean
	 * @param {bool} cookiesAccepted 
	 */
	setCookiesConsent = (cookiesAccepted) => {
		if (cookiesAccepted) {
			setCookie(appConfig.playerDataCookieName, JSON.stringify(this.state.playerData));
		} else {
			this.resetPlayerData(false);
		}
		return new Promise((resolve) => {
			this.setState({
				cookieConsent: cookiesAccepted,
				hasAnsweredConsent: true,
			}, () => { 
				resolve({ status: 'ok' });
			});
		});
	}


	/**
	 * Render component
	 */
	render = () => {
		let triggerFirstTime = this.state.triggerFirstTimeConsent && !this.state.hasAnsweredConsent;

		let PageComponent = Intro;
		if (this.state.page === 'arrival') PageComponent = Arrival;
		if (this.state.page === 'pre-court') PageComponent = PreCourt;
		if (this.state.page === 'court-room') PageComponent = CourtRoom;
		if (this.state.page === 'departure-room') PageComponent = Departure;
		if (this.state.page === 'outro') PageComponent = Outro;


		/* Rooms to be completed before voting is allowed */
		const completeBeforeVoting = courtRoomOrder.slice(1).slice(0, -1); // remove head and tail
		
		/* Get order of popups */
		const popupsOrder = courtRoomOrder.concat(votingRoomOrder);

		/* Is voting (player can be either in court room or in voting room) */
		const isVoting = (
			this.state.popupIndex >= completeBeforeVoting.length + 1 && 
			this.state.popupIndex < popupsOrder.length - 1
		);
		
		/* Get room id and animation */
		const {room, flowAnimation} = getRoomAndAnimation(
			this.state.prevPopupIndex, 
			this.state.popupIndex, 
			this.state.showRoomAnimation, 
			isVoting, 
			this.state.popup, 
			this.state.showLegalFrameWork,
			this.state.tabId, 
			this.state.prevTabId
		);
		
		return (
			<>
				{/* cookie Consent */}
				<CookieConsentController 
					setCookieConsent={this.setCookiesConsent}
					cookieConsentState={this.state.cookieConsent}
					triggerFirstTimeCookieConsent={triggerFirstTime}
				/>
				{/* Game page */}
				<PageComponent
					isStartingGame={this.state.isStartingGame}
					isPaused={this.state.isPaused}
					isStopped={this.state.isStopped}
					showLegalFrameWork={this.state.showLegalFrameWork}
					showPopupVerdict={this.state.showPopupVerdict}
					showPopupTip={this.state.showPopupTip}
					showButtonAnimation={this.state.showButtonAnimation}
					playerData={this.state.playerData}
					room={room}
					flowAnimation={flowAnimation}
					textIndex={this.state.textIndex}
					popup={this.state.popup}
					popupIndex={this.state.popupIndex}
					selectedTabId={this.state.tabId}
					completeRoom={this.completeRoom}
					startGame={this.startGame}
					toggleIsPaused={this.toggleIsPaused}
					setPopup={this.setPopup}
					setPopupIndex={this.setPopupIndex}
					setSelectedTabId={this.setTabId}
					setShowLegalFrameWork={this.setShowLegalFrameWork}
					setShowButtonAnimation={this.setShowButtonAnimation}
					goToTextIndex={this.goToTextIndex}
					updatePlayerData={this.updatePlayerData}
					updateStatsDoc={this.updateStatsDoc}
					getStatsCollection={this.getStatsCollection}
					playClick={this.playClick}
					resetPlayerData={this.resetPlayerData}
					goToPage={this.goToPage}
					handleNavigate={this.handleNavigate}
					nextButtonClick={this.nextButtonClick}
					cookieConsent={this.state.cookieConsent}
				/>
				{/* Game audio */}
				{
					(this.state.playerData && 
					(this.state.page === 'intro' ||
					this.state.page === 'arrival' ||
					this.state.page === 'pre-court' ||
					this.state.page === 'court-room'
					)
				
					) && <GameAudio 
						isPaused={this.state.isPaused}
						isStopped={this.state.isStopped}
						flowAnimation={flowAnimation}
						playerData={this.state.playerData}
						page={this.state.page}
						textIndex={this.state.textIndex}
						popupIndex={this.state.popupIndex}
						tabId={this.state.tabId}
						popup={this.state.popup}
						toggleIsPaused={this.toggleIsPaused}
						goToPage={this.goToPage}
						goToTextIndex={this.goToTextIndex}
						nextButtonClick={this.nextButtonClick}
						handleNavigate={this.handleNavigate}
					/>}
				
				{/* Rotate device prompt */}
				{this.props.deviceInfo.orientation === 'portrait' &&
					<Orientation src={'icon-orientation.svg'} classes={['orientation']} />}
					
				{/* Reset game btn */}
				{(appConfig.env !== 'production' && appConfig.env !== 'demo') && 
					<Button 
						text={introUiTexts.reset} 
						onClick={() => {this.resetPlayerData(true);}} classes={['reset']} 
					/>}
			</>
		);
	}
}

GameController.propTypes = {
	deviceInfo: PropTypes.object,
	handleFullscreen: PropTypes.func,
};

export default GameController;
