const popupData = {
	empty: {
		id: 0,
		title: null,
		texts: null,
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	welcome: {
		id: 1,
		title: 'Dommer',
		texts: [
			{
				id: 1,
				tabId: null,
				text: 'Godmorgen. Vi skal behandle straffesagen mod Christina Jensen om overtrædelse af databeskyttelsesloven. Værsågod, anklager.',
				audioDelay: 3000,
			}	
		],
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	legalTeamProsecutor: {
		id: 2,
		title: null,
		texts: [
			{
				id: 1,
				tabId: null,
				title: 'Anklager',
				text: 'Christina Jensen tiltales for overtrædelse af databeskyttelsesloven. Hun tiltales for at dele billeder af en person uden samtykke fra ham. Billederne blev delt i grupper på Facebook med en tekst om, at personen havde blottet sig foran tiltaltes 11-årige datter.',

				audioDelay: 2000,
			},
			{
				id: 2,
				tabId: null,
				title: 'Anklager',
				text: 'Jeg kan til retten oplyse, at personen på billederne er blevet dømt og straffet for forbrydelsen. Sagen i dag handler derfor kun om Christina Jensens deling af billederne.',

				audioDelay: 500,
			},
			{
				id: 3,
				tabId: null,	
				title: 'Dommer',
				text: 'Hvordan forholder tiltalte sig til tiltalen?',

			}
		],
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	legalTeamDefendant: {
		id: 3,
		title: 'Forsvarer',
		texts: [
			{
				id: 1,
				tabId: null,
				text: 'Min klient nægter sig skyldig. Det er rigtigt, at hun delte billederne på Facebook, men hun gjorde det for at hjælpe politiet med at finde gerningsmanden. Hun mener ikke, at hun har overtrådt loven.',
				audioDelay: 2000,
			},
			
		],
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	judgeAsks: {
		id: 4,
		title: 'Dommer',
		texts: [
			{
				id: 1,
				tabId: null,
				text: 'Lad os høre fra tiltalte. Christina, du har ikke pligt til at udtale dig her i retten i dag, men jeg kan forstå på din forsvarer, at det vil du gerne.',
				audioDelay: 2250,
			},
		],
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	 

	defendant: {
		id: 5,
		title: null,
		texts: [
			{
				id: 1,
				tabId: 1,
				text: 'Vil du fortælle, hvad der skete den dag, hvor du delte billederne på Facebook?',
				audioDelay: 3000,
			},
			{
				id: 2,
				tabId: 2,
				text: 'Min datter kom grædende hjem den dag og fortalte, at en mand havde blottet sig og onaneret foran hende nede ved kiosken. Hun var meget rystet.',
				audioDelay: 500,
			},
			{
				id: 7,
				tabId: 2,
				text: 'Jeg spurgte hende, hvordan han så ud. Jeg var vred og kørte ud for at lede efter ham. Min mand meldte det til politiet.',
				audioDelay: 500,
			},
			{
				id: 8,
				tabId: 2,
				text: 'Senere på dagen så jeg videoover- vågningen fra kiosken igennem. Jeg tog nogle billeder af manden på filmen.',
				audioDelay: 500,
			},
			{
				id: 9,
				tabId: 2,
				text: 'Dem delte jeg på Facebook under overskriften "Hjælp mig med at finde børneblotteren".',
				audioDelay: 500,
			},
			{
				id: 13,
				tabId: 2,
				text: 'Min mand havde sagt til politiet, at der var videoovervågning. Men efter tre timer havde politiet stadig ikke været i kiosken.',
				audioDelay: 500,
			},

			{
				id: 14,
				tabId: 2,
				text: 'Derfor delte jeg billederne på Facebook - både på min egen profil, og i en Facebookgruppe for byens borgere.',
				audioDelay: 500,
			},
			{
				id: 3,
				tabId: 4,
				text: null,
				audioDelay: 500,
			},

			{
				id: 11,
				tabId: 6,
				text: 'Jamen det var jo tydeligt, at politiet var underbemandet. Jeg tænkte, at jeg kunne hjælpe dem. Jeg følte mig nødsaget til at gøre et eller andet. '
			}, 
			
			{
				id: 12,
				tabId: 6,
				text: 'Og det hjalp! Jeg fandt jo blotteren før politiet.',
			},
			{
				id: 4,
				tabId: 5,
				text: 'Hvorfor overlod du det ikke til politiet at kigge på videoovervågning og finde gerningsmanden?',
				
			},
			{
				id: 6,
				tabId: 8,
				text: 'Spørgsmål fra forsvarer text 1',
			},
			],
		tabs: [
			{
				id: 1,
				title: 'Anklager'
			},
			{
				id: 2,
				title: 'Tiltalte'
			},
			{
				id: 3,
				title: 'Normalt stiller domsmænd ikke spørgsmål til tiltalte. Men du får her mulighed for at vælge det næste spørgsmål, som anklager stiller til tiltalte.'
			},
			{
				id: 4,
				title: null
			},
			{
				id: 5,
				title: 'Forsvarer'
			},
			{
				id: 6,
				title: 'Tiltalte'
			},
			{
				id: 7,
				title: 'Normalt stiller domsmænd ikke spørgsmål til tiltalte. Men du får her mulighed for at vælge det næste spørgsmål, som forsvarer stiller til tiltalte.'
			},
			{
				id: 8,
				title: 'Tiltalte'
			},
		],
		questions: [
			{
				id: 1,
				tabId: 3,
				title: 'Anklager',
				text:' Overvejede du konsekvenserne for manden på video- overvågningen. Måske var han uskyldig?',
				prev: 'Normalt stiller domsmænd ikke spørgsmål til tiltalte. Men du får her mulighed for at vælge det næste spørgsmål, som anklager stiller til tiltalte.',
				answer: '<strong>Tiltale</strong> <br> Ikke et sekund. Han passede til den beskrivelse, som min datter gav. Han skulle bare fanges hurtigst muligt, så han ikke kunne krænke andre børn. <$$$> <strong>Anklager</strong> <br> Jeg har ikke flere spørgsmål. <$$$> <strong>Dommer</strong> <br> Værsågod, forsvarer.',

			},
			{
				id: 2,
				tabId: 3,
				title: 'Anklager',
				text: ' Hvornår fjernede du opslagene på Facebook?',
				prev: 'Normalt stiller domsmænd ikke spørgsmål til tiltalte. Men du får her mulighed for at vælge det næste spørgsmål, som anklager stiller til tiltalte.',
				answer: '<strong>Tiltale</strong> <br> Da jeg blev kontaktet af politiet, og de sagde, at det var ulovligt. Så slettede jeg både opslagene og billederne. Alle andres deling af opslaget blev samtidig slettet automatisk på Facebook. <$$$> <strong>Anklager</strong> <br> Jeg har ikke flere spørgsmål. <$$$> <strong>Dommer</strong> <br> Værsågod, forsvarer.',
		
			},
			{
				id: 3,
				tabId: 7,
				title: 'Forsvarer',
				text: 'Hvilke tanker gjorde du dig selv i forhold til at dele personens billede?',
				prev: 'Normalt stiller domsmænd ikke spørgsmål til tiltalte. Men du får her mulighed for at vælge det næste spørgsmål, som forsvarer stiller til tiltalte.',
				answer: 'Jeg var jo nødt til at gøre noget. Jeg vidste, at det var ham. Jeg ville sikre, at han ikke udsatte andre børn for samme modbydelige opførsel. Det er ham, der skal straffes, ikke mig. Alt andet ville være helt urimeligt. Jeg ville gøre det samme igen.',
	

			},
			{
				id: 4,
				tabId: 7,
				title: 'Forsvarer',
				text: 'Overvejede du ikke at vente til politiet fandt gerningsmanden?',
				prev: 'Normalt stiller domsmænd ikke spørgsmål til tiltalte. Men du får her mulighed for at vælge det næste spørgsmål, som forsvarer stiller til tiltalte.',	
				answer: 'Nej - politiet havde jo sagt, at de havde travlt. Så jeg følte, at det var min pligt som borger at hjælpe dem med efterforskningen, når jeg nu havde mulighed for det.',

			},
		],
		hasBackButton: false,
	},
	witnesses: {
		id: 6,
		title: null,
		questions: null,
		texts: [
			{
				id: 1,
				tabId: 1,
				text: 'Hvis der ikke er flere spørgsmål, så vil jeg bede anklageren hente politiassistent Margrethe Andersen, som er indkaldt som vidne i sagen.',
				audioDelay: 1500,
			},
			{
				id: 7,
				tabId: 2,
				text: 'Margrethe, du er her i dag for at afgive forklaring om en sag som vidne. Du har pligt til at svare sandfærdigt på de spørgsmål, som du får og kan blive straffet, hvis du ikke gør.',
				audioDelay: 500,
			},
			{
				id: 2,
				tabId: 3,
				text: 'Margrethe, vil du fortælle om, hvad din rolle er i den her sag?',
				audioDelay: 500,
			},
			{
				id: 3,
				tabId: 4,
				text: 'Jeg arbejder som politiassistent. Omkring klokken 10.30 den pågældende dag modtog jeg en anmeldelse fra en far, der fortalte, at en fremmed mand havde blottet sig foran hans datter uden for en kiosk.',
				audioDelay: 500,
			},
			{
				id: 6,
				tabId: 4,
				text: 'Jeg oprettede sagen i døgnrapporten med en beskrivelse af gerningsmanden, som jeg sendte ud til alle kørende patruljer i området.',
				audioDelay: 500,
			},
			{
				id: 7,
				tabId: 4,
				text: 'Jeg forklarede anmelder, at vi havde travlt med bl.a. et stort røveri, men sagde også, at vi ville gøre alt, hvad vi kunne for at finde gerningsmanden.',
				audioDelay: 500,
			},
			{
				id: 4,
				tabId: 5,
				text: 'Skete der mere i sagen?',
				audioDelay: 500,
			},
			
			{
				id: 5,
				tabId: 6,
				text: 'Jeg fik et opkald fra anmelder igen nogle timer senere. Anmelderen fortalte, at der var TV-overvågning i kiosken, der tydeligt viste, hvordan gerningsmanden så ud.',
				audioDelay: 500,
			},
			{
				id: 9,
				tabId: 6,
				text: 'Jeg svarede, at vi ville se på det, så snart vi fik mulighed for det. Senere fik jeg at vide, at gerningsmanden var blevet anholdt ud fra hans signalement.',
				audioDelay: 500,
			},
			{ 
				id: 10,
				tabId: 7,
				text: ' Hvis der ikke er flere spørgsmål til vidnet, så er den mundtlige bevisførelse afsluttet. ',
				audioDelay: 500,
			},
			
		],
		tabs: [
			{
				id: 1,
				title: 'Dommer',
			},
			{
				id: 2,
				title: 'Dommer'
			},
			{
				id: 3,
				title: 'Anklager'
			},
			{
				id: 4,
				title: 'Vidne'
			},
			{
				id: 5,
				title: 'Forsvarer'
			},
			{
				id: 6,
				title: 'Vidne'
			},
			{
				id: 7,
				title: 'Dommer'
			},
		],
		hasBackButton: false,
	},
	documents: {
		id: 7,
		title: null,
		videos: [
			{
			src: require('assets/videos/surveillance-video.mp4').default,
			tabId: 3,
		},
		],
		texts: [
		
			{ 
				id: 3,
				tabId: 1,
				text: 'Er der noget fra sagens materiale, som skal dokumenteres?',
				audioDelay: 500,
			},
			{
				id: 2,
				tabId: 2,
				text: 'Jeg vil gerne vise videoovervågningen fra kiosken. Jeg vil bede retten være opmærksomme på manden, der kommer ind i kiosken, og på pigen udenfor kioskruden, når I ser videoen. Det er fotos taget af denne video, der er blevet delt på facebook.',
				audioDelay: 500,
			},
			{
				id: 2,
				tabId: 3,
				text: '',
				audio: null,
			},
		],
		tabs: [
			{
				id: 1,
				title: 'Dommer'
			},
			{
				id: 2,
				title: 'Anklager'
			},
			{
				id: 3,
				title: 'OVERVÅGNINGSVIDEO'
			},
		],
		hasBackButton: false,
	},
	personal: {
		id: 8,
		title: null,
		texts: [
			{
				id: 1,
				tabId: null,
				title: 'Dommer',
				text: 'Er der oplysninger om tiltaltes personlige forhold, som bør nævnes?',
				audioDelay: 500,
			},
			{
				id: 2,
				tabId: null,
				title: 'Anklager',
				text: 'Jeg kan oplyse, at tiltalte ikke tidligere er straffet.',
				audioDelay: 500,
			},
			{
				id: 3,
				tabId: null,
				title: 'Forsvarer',
				text: 'Jeg har ikke yderligere.',
				audioDelay: 500,
			}
		],
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	legalTeamProsecutor2: {
		id: 9,
		title: null,
		texts: [
			{
				id: 1,
				tabId: null,
				title: 'Dommer',
				text: 'Det afslutter så bevisførelsen, forstår jeg. Nu får begge parter mulighed for at procedere. Værsågod, anklager.',
				audioDelay: 500,
			},
			{
				id: 2,
				tabId: null,
				title: 'Anklager',
				text: 'Høje domsmandsret. Udgangspunktet er, at deling kun må ske med samtykke.',
				audioDelay: 500,
			},
			{
				id: 3,
				tabId: null,
				title: 'Anklager',
				text: 'Christina Jensen angiver personen på billederne som værende gerningsmand til et strafbart forhold. Han kan nemt genkendes på billederne.',
				audioDelay: 500,
			},
			{
				id: 4,
				tabId: null,
				title: 'Anklager',
				text: 'Den mistænkte har ikke givet sit samtykke til offentliggørelse af billederne. Spørgsmålet er herefter, om der er grunde, der alligevel berettiger en deling, eller gør det lovligt, at Christina Jensen delte billederne. Det finder anklagemyndigheden ikke.',
				audioDelay: 500,

			},
			{
				id: 5,
				tabId: null,
				title: 'Anklager',
				text: 'Anklagemyndigheden finder, at Christina Jensens deling af billederne til en større personkreds uden samtykke ikke varetager en offentlig eller privat interesse, der klart overstiger hensynet til den afbildedes interesse i at holde sin identitet ukendt.',
				audioDelay: 500,
			},
			{
				id: 6,
				tabId: null,
				title: 'Anklager',
				text: 'Delingen kunne medføre, at den pågældende bliver stigmatiseret, uanset om oplysningerne senere måtte vise sig at være rigtige.',
				audioDelay: 500,
			},
			{
				id: 7,
				tabId: null,
				title: 'Anklager',
				text: 'Anklagemyndigheden lægger vægt på, at forholdet var anmeldt til politiet, og at tiltalte kun ventede få timer, før hun lagde til grund, at politiet ikke havde de fornødne ressourcer til sagen og tog det i egen hånd at offentliggøre billederne.',
				audioDelay: 500,
			},
			{
				id: 8,
				tabId: null,
				title: 'Anklager',
				text: 'Der er ikke selvtægt i Danmark, og det er ikke private personers opgave at varetage politiopgaver. Hermed skal jeg afslutte mit indlæg for retten.',
				audioDelay: 500,
			},
			  
		],
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	legalTeamDefendant2: {
		id: 10,
		title: 'Forsvarer',
		texts: [
			{
				id: 1,
				tabId: null,
				text: 'Høje domsmandsret. Det er min opfattelse, at hensynet til at beskytte børn mod seksuelle krænkelser klart overstiger hensynet til at beskytte krænkeren. Særligt når krænkelserne foregår i det offentlige rum, og gerningsmanden er på fri fod.',
				audioDelay: 500,
			},
			{
				id: 2,
				tabId: null,
				text: 'Min klient var helt sikker på, at personen på billederne var gerningsmanden. Så der var ikke nogen risiko for, at en uskyldig blev hængt ud. Billederne blev desuden hurtigt fjernet fra Facebook igen. Det forekommer stødende og ude af trit med retsfølelsen, at en mor, der bidrager til anholdelse af sit eget barns krænker, straffes.',
				audioDelay: 500,

			},
			{
				id: 3,
				tabId: null,
				text: 'Det må desuden anses som en formildende omstændighed, at offentliggørelsen skete som følge af krænkelsen af hendes datter. Jeg skal hermed afslutte mit indlæg for retten.',
				audioDelay: 500,
			},
		],
		tabs: null,
		images: null,
		questions: null,
		hasBackButton: false,
	},
	defendant2: {
		id: 11,
		title: null,
		texts: [
			{
				id: 1,
				tabId: 1,
				text: 'Christina - har du noget at tilføje til det, som din forsvarer har fremført?',
				audioDelay: 500,
			},
			{
				id: 1,
				tabId: 2,
				text: 'Jeg vil bare gerne understrege, at jeg ville sikre, at han ikke udsatte andre børn for samme modbydelige opførsel. Det er ham, der er kriminel, ikke mig. Jeg ville gøre det samme igen for børnenes skyld.',
				audioDelay: 500,
			},

			{
				id: 1,
				tabId: 3,
				text: 'Dommer og domsmænd vil nu trække sig tilbage og gå til voteringslokalet og overveje sagen.',
				audioDelay: 500,
			},
		],
		tabs: [
			{
			id: 1,
			title: 'Dommer',
		},
		{
			id: 2,
			title: 'Tiltalte'
		},
		{
			id: 3,
			title: 'Dommer'
		},
		],
		images: null,
		questions: null,
		hasBackButton: false,
	},
	
	talkToJudge: {
		id: 14,
		title: null,
		texts: [
			{
				id: 1,
				tabId: 1,
				text: 'Tak dommer. Selvom jeg godt forstår, at hun er oprørt over datterens oplevelse, så mener jeg, at hun ikke har givet politiet en reel chance for at reagere på anmeldelsen. Hun er i sin oprørthed selv skredet til handling.',
				audioDelay: 500,

			},
			{
				id: 2,
				tabId: 1,
				text: 'Det har et skær af selvtægt, som vi – også af hensyn til betydningen for andre sager - ikke bør acceptere. Jeg mener derfor, at hun ved sin overilede handling er skyldig i den rejste tiltale.',
				audioDelay: 500,
			},

			{
				id: 3,
				tabId: 3,
				text: null,
			},

			{
				id: 4,
				tabId: 3,
				text: null,
			},
			{
				id: 5,
				tabId: 3,
				text: null,
			},
			
		],
		tabs: [
		{
			id: 1,
			title: 'Domsmand',
		},
		{
			id: 2,
			title: 'Og hvad tænker du om sagen? Synes du, at Christina Jensen er skyldig eller ej?',
		},
		{
			id: 3,
			title: null
		},
		],
		images: null,
		questions: [
			{
				id: 1,
				tabId: 2,
				title: null,
				prev: 'Og hvad tænker du om sagen? Synes du, at Christina Jensen er skyldig eller ej?',
				text:'Ja',
				answer: '<strong>Dommer</strong> <br> Jeg synes, at tiltalte er skyldig i den rejste tiltale. Hun har erkendt, at hun har delt billedene. Episoden var anmeldt til politiet, der – omend ikke straks, så dog kort efter - sikrede sig videoovervågningen, og på det grundlag identificerede gerningsmanden. <$$$> <strong>Dommer</strong> <br> Vi træffer afgørelse efter flertallet og kan lægge til grund, at retten finder, at den tiltalte er skyldig i den rejste tiltale. <$$$> <strong>Dommer</strong> <br> Vi skal herefter fastsætte straffen.',

			},
			{
				id: 2,
				tabId: 2,
				title: null,
				prev: 'Og hvad tænker du om sagen? Synes du, at Christina Jensen er skyldig eller ej?',
				text: 'Nej',
				answer: `<strong>Dommer</strong><br>Jeg er enig med den første domsmand i, at tiltalte er skyldig i den rejste tiltale. Episoden var anmeldt til politiet, der – omend ikke straks, så dog kort efter - sikrede sig videoovervågningen, og på det grundlag identificerede gerningsmanden.<$$$><strong>Dommer</strong><br>Vi kan herefter lægge til grund, at tiltalte er skyldig i den rejste tiltale, da der er flertal herfor. <$$$><strong>Dommer</strong><br>Vi skal nu fastsætte straffen. `,

			},
		],
		hasBackButton: false
	},
	sentencing: {
		id: 15,
		title: null,
		texts: [
			{
				id: 1,
				tabId: 1,
				text: 'Thi kendes for ret. <br> Tiltalte er skyldig i overtrædelse af databeskyttelsesloven. Straffen fastsættes til en bøde på 5.000 kr.',
				audioDelay: 3000,
			},
			{
				id: 2,
				tabId: 1,
				text: 'Retten har lagt vægt på, at billederne blev delt til en større gruppe personer, at forholdet var anmeldt til politiet, og at tiltalte kun ventede to timer med at offentliggøre billeder af gerningsmanden fra overvågningsvideoen. <br> Hermed afsluttes sagen.',
				audioDelay: 500,
			},
		],
		tabs: [
			{
			id: 1,
			title: 'Dommer',
			},
		],
		images: null,
		questions: null,
		hasBackButton: false
	},
}

const courtRoomOrder = ['empty','welcome', 'legalTeamProsecutor',  'legalTeamDefendant', 'judgeAsks', 'defendant', 'witnesses', 'documents', 'personal', 'legalTeamProsecutor2', 'legalTeamDefendant2', 'defendant2', 'voting']
const votingRoomOrder = ['talkToJudge', 'giveVerdict', 'sentencing']

export {
	courtRoomOrder,
	votingRoomOrder,
	popupData
};