import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {getTextAndQuestionData} from 'helpers/game-helper';
import {getAudioData, getNextCourtRoomStep} from 'helpers/audio-helper';
import AudioPlayer from 'react-h5-audio-player';
import {ReactSVG} from 'react-svg';
import './game-audio.scss';

const GameAudio = (props) => {
	const {
		isPaused,
		isStopped,
		page, 
		textIndex, 
		popupIndex,
		tabId, 
		playerData, 
		popup,
		flowAnimation,
		toggleIsPaused, 
		goToPage,
		goToTextIndex,
		handleNavigate,
		nextButtonClick
	} = props;

	/* Refs to timouts and audio player */
	let timeoutPlay = useRef();
	let timeoutPause = useRef();
	let audioPlayerRef = useRef();

	/* Get audio file on first render */
	const [audioSrc] = useState(require('../../assets/audio/combined-audio.mp3').default);

	/* Show / hide audio player */
	let [showAudioPlayer, setShowAudioPlayer] = useState(false);

	/* Get audio data for text */
	const currentAudioData = getAudioData(page, textIndex, popupIndex, tabId, playerData);

	/* Adjust delay wrt animation */
	let audioDelay = (currentAudioData && currentAudioData.audioDelay ? currentAudioData.audioDelay : 0);
	if (audioDelay && !flowAnimation.includes('animation')) {
		/* Default */
		audioDelay = 0;

		/* Legal framework */
		if (popupIndex === 12) audioDelay = 500; 
	}
	audioDelay += 500;

	/* Autoplay */
	const autoPlay = (page !== 'intro' && !isPaused && !isStopped ? true : false);

	/**
	 * Handle play
	 */
	const handleOnPlay = () => {
		/* Update is paused & is stopped */
		toggleIsPaused(false, false);

		/* Clear timeouts */
		if (timeoutPlay.current) clearTimeout(timeoutPlay.current);
		if (timeoutPause.current) clearTimeout(timeoutPause.current);
		
		/* Auto-pause when clip ends */
		if (currentAudioData) {	
			timeoutPause.current = setTimeout(() => {
				audioPlayerRef.current.audio.current.pause();
				goToNextStep();
			}, ((currentAudioData.audioDuration * 1000) / 30.) + 500);
		}
	};

	/**
	 * Handle pause
	 * @param {string} trigger
	 */
	const handleOnPause = (trigger) => {
		/* Toggle isPaused if user initiated pause */
		if (trigger === 'user') {
			toggleIsPaused(true);
		}

		/* Clear timeouts */
		if (timeoutPause.current) clearTimeout(timeoutPause.current);	
	};

	/**
	 * Handle stop
	 */
	const handleOnStop = () => {
		/* Update is paused & is stopped */
		toggleIsPaused(true, true);

		/* Stop audio */
		audioPlayerRef.current.audio.current.pause();

		/* Reset playback */
		if (currentAudioData && currentAudioData.audioStart >= 0) {
			audioPlayerRef.current.audio.current.currentTime = (currentAudioData.audioStart / 30.);
		}
	};


	/** 
	 * Audio auto-paused, go to next step
	 * */ 
	const goToNextStep = () => {
		if (page === 'court-room') {
			/* court room */
			const nextStep = getNextCourtRoomStep(textIndex, popupIndex, tabId, popup, playerData);
			if (nextStep) {
				if (nextStep.type === 'nextBtnClick') {
					const textQuestionAndAudioData = getTextAndQuestionData(popup, tabId, playerData);
					nextButtonClick(textQuestionAndAudioData);
				}
				if (nextStep.type === 'textIndex') {
					goToTextIndex(nextStep.nextIndex);
				}
				if (nextStep.type === 'popupIndex') {
					handleNavigate(nextStep.nextIndex);
				}
			}
		} else {
			/* Arrival / pre-court page / outro */
			goToPage(
				(currentAudioData.nextPage ? currentAudioData.nextPage : page),
				(currentAudioData.nextTextIndex ? currentAudioData.nextTextIndex : 0)
			);
		}		
	};

	/**
	 * Audiodata updated, pause then start after delay
	 */
	useEffect(() => {
		/* Pause audio */
		audioPlayerRef.current.audio.current.pause();

		/* Hide autoplayer */
		setShowAudioPlayer(false);

		/* Initial new audio playback */
		if (currentAudioData && currentAudioData.audioStart >= 0) {
			audioPlayerRef.current.audio.current.currentTime = (currentAudioData.audioStart / 30.);

			setShowAudioPlayer(true);
			if (autoPlay) {
				timeoutPlay.current = setTimeout(() => {					
					audioPlayerRef.current.audio.current.play();
				}, audioDelay);
			}
			
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentAudioData]);

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		return () => {
			/* Component will unmount - clear timeouts */
			if (timeoutPlay.current) clearTimeout(timeoutPlay.current);
			if (timeoutPause.current) clearTimeout(timeoutPause.current);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'GameAudio' + (showAudioPlayer ? ' show' : '')}>
			<AudioPlayer
				ref={audioPlayerRef}
				muted={false}
				autoPlay={autoPlay}
				preload="metadata"
				src={audioSrc + '#t=' + 609}
				width="100%"
				height="100%"
				showJumpControls={false}
				showSkipControls={false}
				showDownloadProgress={false}
				showFilledProgress={false}
				showFilledVolume={false}
				onPlay={() => {handleOnPlay(); }}
				onPause={() => {handleOnPause();}}
				onLoadedMetaData={() => {console.log('metadata');}}
				onChangeCurrentTimeError={(e) => {console.log(e);}}
				customIcons={{
					play: <ReactSVG src={require('../../assets/images/icon-play.svg').default} />,
					pause: <ReactSVG 
						onClick={() => {handleOnPause('user');}}
						src={require('../../assets/images/icon-pause.svg').default} 
					/>,
				}}
			/>
			<div 
				className={'GameAudio-muteIcon' + (isStopped ? ' muted' : '')} 
				onClick={() => {
					if (isStopped) {
						audioPlayerRef.current.audio.current.play();
						handleOnPlay();
					} else {
						handleOnStop();
					}
				}}
			/>
		</div>
	);
};

GameAudio.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	isStopped: PropTypes.bool.isRequired,
	page: PropTypes.string.isRequired,
	textIndex: PropTypes.number.isRequired,
	popupIndex: PropTypes.number.isRequired,
	tabId: PropTypes.number.isRequired,
	playerData: PropTypes.object.isRequired,
	popup: PropTypes.object,
	flowAnimation: PropTypes.string,
	toggleIsPaused: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
	goToTextIndex: PropTypes.func.isRequired,
	handleNavigate: PropTypes.func.isRequired,
	nextButtonClick: PropTypes.func.isRequired,
};

export default GameAudio;