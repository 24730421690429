import apiHelper from './api-helper';
import appConfig from 'config/app.config';

function setCookie(cname, cvalue) {
	let midnight = new Date();
	midnight.setHours(23, 59, 59, 0);

	let year = new Date();
	year.setTime(year.getTime() + (365 * 24 * 60 * 60 * 1000));

	let expires = 'expires=' + (cname === appConfig.playerDataCookieName ? midnight.toUTCString() : year.toUTCString());
	/** encrypt the cookie and return the ciphertext */
	apiHelper('cookie/encrypt', {
		data: cvalue,
	}).then((response) => {
		if (response && response.data.length > 0) {
			document.cookie = cname + '=' + response.data + ';' + expires + ';samesite=lax;path=/';
		} else {
			console.error('Cookie encryption failed');
		}
	})
		.catch((rejection) => {
			console.error(rejection);
		});

}

function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	let cookieValue = '';
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			cookieValue = c.substring(name.length, c.length);
		}
	}
	/* if the cookie is empty return empty data */
	if (cookieValue === '') {
		return new Promise((resolve) => {resolve({status: 'fail', data: ''});});
	}
	/** if the cookie is not empty decrypt it and return the plaintext */
	return new Promise((resolve, reject) => {
		apiHelper('cookie/decrypt', {
			data: cookieValue,
		}).then(
			(response) => {
				resolve(response);
			})						
			.catch((rejection) => {
				reject(rejection);
			});
	});
}

function deleteCookie(cname) {
	document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
}

export {
	setCookie,
	getCookie,
	deleteCookie
};