import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {courtRoomUiTexts} from 'data/ui-texts';
import renderMarkdown from 'helpers/text-helper';
import Carousel, {CarouselItem} from 'components/carousel/carousel';
import './legal-framework.scss';

const LegalFramework = (props) => {
	const {
		canContinue,
		classnames, 
		showButtonAnimation, 
		updatePlayerData,
		textIndex,
		goToTextIndex
	} = props;
	

	/**
	 * checks if the player has completed all text before allowing then to keep going
	 */
	useEffect(() => {
		if (
			textIndex === courtRoomUiTexts.legalFramework.texts.length - 1 &&
			!canContinue
		) {
			updatePlayerData({isLegalFrameworkComplete: true});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textIndex]);

	return (
		<div className={`LegalFramework ${classnames}`}>
			<div className="LegalFramework-body">
				{/* Texts */}
				{<div className="LegalFramework-texts">
					<Carousel goToTextIndex={goToTextIndex} activeIndex={textIndex} 
						amountOfTexts={courtRoomUiTexts.legalFramework.texts.length}
						isNextText = {showButtonAnimation}>
						{courtRoomUiTexts.legalFramework.texts.map((text, index) => {
							
							return <CarouselItem key={index}>
								<div className="LegalFramework-text">{text ? renderMarkdown(text) : ''}</div>
							</CarouselItem>;
						})}
					</Carousel>
				</div>}
			</div>
		</div>
	);

};

LegalFramework.propTypes = {
	canContinue: PropTypes.bool.isRequired,
	classnames: PropTypes.string.isRequired,
	showButtonAnimation: PropTypes.bool.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	textIndex: PropTypes.number.isRequired,
	goToTextIndex: PropTypes.func.isRequired
};

export default LegalFramework;