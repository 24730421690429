import React from 'react';
import PropTypes from 'prop-types';
import { cookieUiTexts } from 'data/ui-texts';
import './cookie-consent.scss';
import renderMarkdown from 'helpers/text-helper';

const CookieConsent = ({
	handleClosePopup, 
	cookieConsentState, 
	openConsent, 
	showCookieConsent, 
	triggerFirstTimeCookieConsent,
	showMore,
	toggleReadMore
}) => {
	return (
		<div className='CookieConsent'>
			<div className={'CookieConsent-popup' + 
				(showCookieConsent || triggerFirstTimeCookieConsent ? ' show' : '')
				+ ( showMore ? ' big' : '')}>
				{showMore ? 
					<div className='CookieConsent-textWrapper'>
						<div className='CookieConsent-textBody'>
							<span>{renderMarkdown(cookieUiTexts.bigtext)}</span>
						</div>
					</div>
					:
					<div className='CookieConsent-textWrapper'>
						<div className='CookieConsent-textBody'>
							<span className='CookieConsent-readMore'
								onClick={() => {toggleReadMore();}}
							>{renderMarkdown(cookieUiTexts.readMore)}</span>
							<span>{renderMarkdown(cookieUiTexts.text)}</span>
						</div>
					</div>
				}
				<div className='CookieConsent-buttonWrapper'>
					<div className='CookieConsent-button Ok' onClick={() => {handleClosePopup(true);}}>
						<div className='CookieConsent-buttonText'>{cookieUiTexts.consentButtons.ok}</div>
					</div>
					<div className='CookieConsent-button Cancel' onClick={() => {handleClosePopup(false);}}>
						<div className='CookieConsent-buttonText'>{cookieUiTexts.consentButtons.cancel}</div>
					</div>
				</div>
			</div>
			<div className={'CookieConsent-consentWrapper' + 
				(showCookieConsent || triggerFirstTimeCookieConsent ? ' show' : '')}>
				<div className={'CookieConsent-consentToggleContainer' + (cookieConsentState ? 
					' Accepted' : '')} onClick={() => {openConsent();}}/>
			</div>
		</div>
	);	
};

CookieConsent.propTypes = {
	cookieConsentState: PropTypes.bool.isRequired,
	showCookieConsent: PropTypes.bool.isRequired,
	handleClosePopup: PropTypes.func.isRequired,
	openConsent: PropTypes.func.isRequired,
	triggerFirstTimeCookieConsent: PropTypes.bool.isRequired,
	showMore: PropTypes.bool.isRequired,
	toggleReadMore: PropTypes.func.isRequired,
};

export default CookieConsent;