import React from 'react';
import './loading.scss';

const Loading = () => {
	return (
		<div className="Loading">
			<div className="Loading-circles">
				<div className="Loading-circle" />
				<div className="Loading-circle" />
				<div className="Loading-circle" />
			</div>
		</div>
	);
};



export default Loading;