import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {sortArrayByProperty} from 'helpers/array-helper';
import {statsUiTexts, verdictUiTexts} from 'data/ui-texts';
import {localizationData} from 'data/localization-data';
import DatePicker, { registerLocale } from 'react-datepicker';
import Button from 'components/button/button';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './stats.scss';

const Stats = ({stats, handleLogout}) => {
	/* Localize dates */
	registerLocale('da', {
		localize: {
			month: (n) => {return localizationData.months[n];},
			day: (n) => {return localizationData.days[n];}
		}, 
		formatLong:{
			date: () => {return 'yyyy-mm-dd';}
		} 
	});

	/* Get earliest creation date */
	const firstDate = (stats.length > 0 
		? dayjs(sortArrayByProperty(stats, 'created', 'ASC')[0].created).format('YYYYMMDD')
		: dayjs(new Date()).format('YYYYMMDD')
	);

	/* Start and end dates */
	const [startDate, setStartDate] = useState(firstDate);
	const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYYMMDD'));

	/* Filter by dates */ 
	const filteredStats = stats.filter((s) => {
		return (
			s.hasOwnProperty('created') && 
			s.created >= startDate &&
			s.created <= endDate
		);
	});

	/* Get total stats */
	const totalStarted = filteredStats.length;
	const totalCompleted = filteredStats.filter((s) => {return (s.verdict);}).length;
	const totalCompletedPercent = (totalStarted > 0
		? Math.round(((totalCompleted / totalStarted) * 100.))
		: 0
	);

	/* Get weekday plays */
	let weekdayPlayers = [0, 0, 0, 0, 0, 0, 0];

	/* Get verdicts */
	let verdictNames = [
		verdictUiTexts.verdict1, 
		verdictUiTexts.verdict2, 
		verdictUiTexts.verdict3, 
		verdictUiTexts.verdict4, 
		verdictUiTexts.verdict5
	];
	let verdictsSelected = [0, 0, 0, 0, 0];

	filteredStats.forEach((stat) => {
		if (stat.created) {
			const weekdayIndex = dayjs(stat.created).day() - 1;
			if (weekdayIndex >= 0 && weekdayIndex < 7) weekdayPlayers[weekdayIndex] = weekdayPlayers[weekdayIndex] + 1;
		}
		if (stat.verdict) {
			const verdictIndex = stat.verdict - 1;
			if (verdictIndex >= 0 && verdictIndex < 5) {
				verdictsSelected[verdictIndex] = verdictsSelected[verdictIndex] + 1;
			}
		}
	});

	return (
		<div className="Stats">
			<Button classes={['logout']} text={statsUiTexts.logout} onClick={() => {handleLogout();}} />
			<div className="Stats-title">{statsUiTexts.statsTitle}</div>
			<div className="Stats-period">
				<DatePicker
					disabled={false}
					selected={startDate ? dayjs(startDate).toDate() : new Date()}
					onChange={(date) => {setStartDate(dayjs(date).format('YYYYMMDD'));}}
					locale={'da'}
					dateFormat="dd/MM-yyyy"
					placeholderText="dd/MM-yyyy"
				/>
				<div className="Stats-periodTo"><span>til</span></div>
				<DatePicker
					disabled={false}
					selected={endDate ? dayjs(endDate).toDate() : new Date()}
					onChange={(date) => {setEndDate(dayjs(date).format('YYYYMMDD'));}}
					locale={'da'}
					dateFormat="dd/MM-yyyy"
					placeholderText="dd/MM-yyyy"
				/>
			</div>

			{/* Genereal stats */}
			<div className="Stats-section">
				<div className="Stats-sectionRow">
					<span>{statsUiTexts.gamesStarted}:</span> <span>{totalStarted}</span>
				</div>
				<div className="Stats-sectionRow">
					<span>{statsUiTexts.gamesFinished}:</span> <span>{totalCompleted} ({totalCompletedPercent}%)</span>
				</div>
			</div>

			{/* Verdict histogram */}
			<div className="Stats-histogram verdict">
				<div className="Stats-histogramCols">
					{verdictsSelected.map((verdict, index) => {
						let height = (totalCompleted > 0 ? (100. * verdict / totalCompleted) : 0);
						return (
							<div key={index} className="Stats-histogramCol">
								<div key={index} className="Stats-histogramColFill" style={{height: height + '%'}}>
									<span>{(height > 0 ? verdict + ' (' + height.toFixed(0) + '%)' : '')}</span>
								</div>
							</div>
						);
					})}
				</div>
				<div className="Stats-histogramLabels">
					{verdictNames.map((_, index) => {
						return <div key={index} className="Stats-historgramLabel">
							<span>{verdictNames[index]}</span></div>;
					})}
				</div>
			</div>

			{/* Weekday histogram */}
			<div className="Stats-histogram">
				<div className="Stats-histogramCols">
					{weekdayPlayers.map((weekday, index) => {
						let height = (100. * weekday / totalStarted);
						return (
							<div key={index} className="Stats-histogramCol">
								<div key={index} className="Stats-histogramColFill" style={{height: height + '%'}}>
									<span>{(height > 0 ? weekday + ' (' + height.toFixed(0) + '%)' : '')}</span>
								</div>
							</div>
						);
					})}
				</div>
				<div className="Stats-histogramLabels">
					{localizationData.days2.map((dayName, index) => {
						return <div key={index} className="Stats-historgramLabel">
							<span>{dayName}</span></div>;
					})}
				</div>
			</div>

		</div>
	);
};

Stats.propTypes = {
	stats: PropTypes.array.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Stats;