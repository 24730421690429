import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAWRWDtaX2ROL8AueGUYXD0fGDAlx-N2y8',
		authDomain: 'cgl-retssalsspil-test.firebaseapp.com',
		projectId: 'cgl-retssalsspil-test',
		storageBucket: 'cgl-retssalsspil-test.appspot.com',
		messagingSenderId: '137939830650',
		appId: '1:137939830650:web:0c2255089003cdca2ef8c3'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyAHWIaoprzNfrsxQm766UljXbF6XNpIAT4',
		authDomain: 'cgl-retssalsspil-production.firebaseapp.com',
		projectId: 'cgl-retssalsspil-production',
		storageBucket: 'cgl-retssalsspil-production.appspot.com',
		messagingSenderId: '1037190715060',
		appId: '1:1037190715060:web:676095933dee79bf41e9e1',
		measurementId: 'G-QLVMC1EM61'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;