const imagesDataInitial = [
	'backgrounds/whiteoverlay.png',
	'backgrounds/intro-1.jpg',
	'backgrounds/arrival.jpg',
	'logo-courts-of-denmark.svg',
	'logo-courts-of-denmark2.svg',
	'next.svg',
	'prev.svg',
	'icon-unmute.svg',
	'icon-loading.svg',
	'icon-mute-intro.svg',
	'icon-notmute-intro.svg',
	'icon-prev.svg',
	'icon-next.svg',
	'icon-mute.svg',
	'icon-mute2.svg',
	'icon-pause.svg',
	'icon-pause2.svg',
	'icon-play.svg',
	'icon-play2.svg',
	'backgrounds/voting-2.jpg',
	'backgrounds/witnesses-3.jpg',
];

const imagesData = [
	'backgrounds/welcome-1.jpg',
	'backgrounds/welcome-2.jpg',
	'backgrounds/welcome-3.jpg',
	'backgrounds/defendant-1.jpg',
	'backgrounds/defendant-2.jpg',
	'backgrounds/depature.jpg',
	'backgrounds/documents-1.jpg',
	'backgrounds/legal-team-defendant.jpg',
	'backgrounds/legal-team-prosecutor.jpg',
	'backgrounds/sentencing-1.jpg',
	'backgrounds/sentencing-2.jpg',
	'backgrounds/voting-1.jpg',
	'backgrounds/witnesses-1.jpg',
	'backgrounds/witnesses-2.jpg',
	'backgrounds/witnesses-3.jpg',
	'backgrounds/clock/defendant.png',
	'backgrounds/clock/defendant2.png',
	'backgrounds/clock/documents.png',
	'backgrounds/clock/judge-asks.png',
	'backgrounds/clock/legal-team-defendant.png',
	'backgrounds/clock/legal-team-defendant2.png',
	'backgrounds/clock/legal-team-prosecutor.png',
	'backgrounds/clock/legal-team-prosecutor2.png',
	'backgrounds/clock/sentencing.png',
	'backgrounds/clock/voting.png',
	'backgrounds/clock/welcome.png',
	'backgrounds/clock/witnesses.png',
	'backgrounds/paper/defendant.png',
	'backgrounds/paper/defendant2.png',
	'backgrounds/paper/documents.png',
	'backgrounds/paper/judge-asks.png',
	'backgrounds/paper/legal-team-defendant.png',
	'backgrounds/paper/legal-team-defendant2.png',
	'backgrounds/paper/legal-team-prosecutor.png',
	'backgrounds/paper/legal-team-prosecutor2.png',
	'backgrounds/paper/sentencing.png',
	'backgrounds/paper/voting.png',
	'backgrounds/paper/welcome.png',
	'backgrounds/paper/witnesses.png',
	'backgrounds/witnesses-legal-team-defendant.jpg',
	'backgrounds/witnesses-prosecutor.jpg',
	'backgrounds/witnesses-witnesses.jpg',
	'backgrounds/legal-team-defendant2-legal-team-defendant.jpg',
	'backgrounds/documents-prosecutor.jpg',
	'backgrounds/defendant2-defendant.jpg',
	'backgrounds/defendant-prosecutor.jpg',
	'backgrounds/defendant-legal-team-defendant.jpg',
	'backgrounds/defendant-defendant.jpg',
	'backgrounds/defendant-1-3.jpg',
	'backgrounds/defendant-1-2.jpg',
	
]

const videoData = ['assets/videos/surveillance-video.mp4'];


module.exports = {
	imagesDataInitial,
	imagesData, 
	videoData
};
