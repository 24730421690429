import {audioData} from 'data/audio-data';
import {popupData, courtRoomOrder} from 'data/popup-data';
import {courtRoomUiTexts} from 'data/ui-texts';
import {getTextAndQuestionData} from 'helpers/game-helper';

/**
 * Get player answer to current question (if any)
 * @param {object} playerData 
 * @returns 
 */
function getPlayerAnswer(page, textIndex, popupIndex, tabId, playerData) {
	let playerAnswer = null;
	if (page === 'pre-court' && textIndex === 1 && playerData.hasOwnProperty('preCourtAnswer')) {
		// Pre court - judge question
		playerAnswer = playerData.preCourtAnswer;
	}
	if (
		page === 'court-room' && 
		textIndex === 0 && 
		popupIndex === 5 && 
		tabId === 4 && 
		playerData.questionsAsked.length > 0
	) {
		// Court room - defendant question 1
		const playerAnswerData = playerData.questionsAsked.find((q) => {
			return (q.tabId === 3 && q.type === 'defendant');
		});
		if (playerAnswerData) playerAnswer = playerAnswerData.id;
	}
	if (
		page === 'court-room' && 
		textIndex === 0 && 
		popupIndex === 5 && 
		tabId === 8 && 
		playerData.questionsAsked.length > 0
	) {
		// Court room - defendant question 2
		const playerAnswerData = playerData.questionsAsked.find((q) => {
			return (q.tabId === 7 && q.type === 'defendant');
		});
		if (playerAnswerData) playerAnswer = playerAnswerData.id;
	}
	if (
		page === 'court-room' && 
		textIndex <= 2 && 
		popupIndex === 13 && 
		tabId === 3 && 
		playerData.questionsAsked.length > 0
	) {
		// Court room - talk to judge
		const playerAnswerData = playerData.questionsAsked.find((q) => {
			return (q.tabId === 2 && q.type === 'talkToJudge');
		});
		if (playerAnswerData) playerAnswer = playerAnswerData.id;
	}

	return playerAnswer;
};

/**
 * Get audio data for current text
 * @param {string} page 
 * @param {number} textIndex 
 * @param {number} popupIndex 
 * @param {number} tabId 
 * @param {object} playerData 
 * @returns 
 */
function getAudioData(page, textIndex, popupIndex, tabId, playerData) {
	/* Get player answer for current question (if any) */
	const playerAnswer = getPlayerAnswer(page, textIndex, popupIndex, tabId, playerData);

	const aData = audioData.find((a) => {		
		return (
			a.page === page &&
			a.textIndex === textIndex &&			
			(
				page !== 'court-room' ||
				(
					a.popupIndex === popupIndex &&
					a.tabId === tabId 
				)
			) &&
			// Pre court - judge question
			(
				page !== 'pre-court' || 
				textIndex !== 1 ||				
				playerAnswer === a.preCourtAnswer
			) &&
			// Court room - defendant question 1
			(
				page !== 'court-room' ||
				textIndex !== 0 ||
				popupIndex !== 5 ||
				tabId !== 4 ||
				playerAnswer === a.playerAnswer
			) &&
			// Court room - defendant question 2
			(
				page !== 'court-room' ||
				textIndex !== 0 ||
				popupIndex !== 5 ||
				tabId !== 8 ||
				playerAnswer === a.playerAnswer
			) &&
			// Court room - talk to judge
			(
				page !== 'court-room' ||
				textIndex > 2 ||
				popupIndex !== 13 ||
				tabId !== 3 ||
				playerAnswer === a.playerAnswer
			)
		);
	});

	return (aData ? aData : null);
};

/**
 * Get next courtroomm step 
 * @param {number} textIndex 
 * @param {number} popupIndex 
 * @param {number} tabId 
 * @param {object} popup 
 * @param {object} playerData 
 */
function getNextCourtRoomStep(textIndex, popupIndex, tabId, popup, playerData) {
	/* Rooms to be completed before voting is allowed */
	const completeBeforeVoting = courtRoomOrder.slice(1).slice(0, -1); // remove head and tail
	
	/* Current popup text & questions */
	const textQuestionAndAudioData = getTextAndQuestionData(popup, tabId, playerData);

	/* Default popup */
	if (
		popupIndex <= completeBeforeVoting.length || 
		popupIndex === completeBeforeVoting.length + 2 ||
		popupIndex === completeBeforeVoting.length + 4
	) {	
		const tabs = popupData[popup.type].tabs;
		if (tabs) {
			/* Selected tab is not last tab */
			if (tabId !== tabs.length) {
				/* Same tab (not last), next text */
				if (textIndex !== textQuestionAndAudioData.availableTexts.length - 1) {
					return {type: 'textIndex', nextIndex: textIndex + 1};
				}
				/* Next tab */
				return {type: 'nextBtnClick'};
			}

			/* Selected tab is last tab */
			const type = popup.type;
			if (type === 'defendant' || type === 'witnesses' || type === 'defendant2') {
				/* Next popup */
				return ({type: 'popupIndex', nextIndex: popupIndex + 1});
			}
			if (type === 'talkToJudge') {
				if (textIndex !== textQuestionAndAudioData.availableTexts.length - 1) {
					/* Next text */
					return {type: 'textIndex', nextIndex: textIndex + 1};
				}
				/* Next popup */
				return ({type: 'popupIndex', nextIndex: popupIndex + 1});
			}
			if (type === 'sentencing') {
				if (textIndex === 0) {
					/* Next text */
					return {type: 'textIndex', nextIndex: textIndex + 1};
				}
				if (textIndex === 1) {
					/* Next popup */
					return ({type: 'popupIndex', nextIndex: popupIndex + 1});
				}
			}

		} else {
			/* No tabs */
			if (textIndex !== textQuestionAndAudioData.availableTexts.length - 1) {
				/* Next text */
				return {type: 'textIndex', nextIndex: textIndex + 1};
			}
			/* Next popup */
			return ({type: 'popupIndex', nextIndex: popupIndex + 1});
		}
	}
	
	/* Legal framework popup */
	if (popupIndex === completeBeforeVoting.length + 1) {
		if (textIndex !== courtRoomUiTexts.legalFramework.texts.length - 1) {
			/* Next text */
			return {type: 'textIndex', nextIndex: textIndex + 1};
		}
		/* Next popup */
		return ({type: 'popupIndex', nextIndex: popupIndex + 1});
	}

	return null;
};

export {
	getAudioData,
	getNextCourtRoomStep
};