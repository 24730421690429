import {useState, useEffect} from 'react';
import firebaseInit from 'firebase-init';
import appConfig from 'config/app.config';
import {getOrientation, getAspectRatio, checkIfInStandaloneMode, isMobileOrTablet} from 'helpers/device-helper';
import { imagesData, videoData, imagesDataInitial } from 'data/preload-data';
import StatsLoginController from 'components/stats/stats-login-controller';
import ImageLoader from 'components/image-loader/image-loader';
import Loading from './loading/loading';
import GameController from 'components/game/game-controller';
import './app.scss';

const App = () => {
	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {
			isInStandaloneMode: checkIfInStandaloneMode(),
			isUsingTouch: false,
			orientation: getOrientation(),
			aspectRatio: getAspectRatio(),
			deviceType: (isMobileOrTablet() ? 'mobile' : 'desktop')
		};
	});

	/**
	* Toggle fullscreen
	*/
	const handleFullscreen = () => {
		let elem = document.documentElement;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { /* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE/Edge */
			elem.msRequestFullscreen();
		}
	};
	
	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.orientation = getOrientation();
		newDeviceInfo.aspectRatio = getAspectRatio();
		setDeviceInfo(newDeviceInfo);
	};

	/**
	 * User interacted with a touch (first time only)
	 * We check for touch interaction, because we wnat to
	 * know what is used (not what is available).
	 */
	const handleTouchStart = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.isUsingTouch = true;
		setDeviceInfo(newDeviceInfo);
		if (document.removeEventListener) window.removeEventListener('touchstart', handleTouchStart, false);
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Redirect */
		if (window.location.pathname.toLowerCase().includes('/was')) {
			window.location.href = appConfig.availabilityStatementLink;
		}


		/* Initialize firebase */
		firebaseInit();

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Add event listener for touch start */
		if (document.addEventListener) window.addEventListener('touchstart', handleTouchStart, false);
		
		/* Component will ummount */
		return () => {
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
			if (document.removeEventListener) window.removeEventListener('touchstart', handleTouchStart, false);
		};
	});

	/* Images preloading */
	const [isPreloadingImages, setIsPreloadingImages] = useState(true);
	
	/* 
	* Check if all images have been preloaded 
	*/
	const preloadCallback = () => {
		setIsPreloadingImages(false);
	};

	/* Stats section */
	if (window.location.pathname.toLowerCase().includes('stats')) {
		return <div id="app" className="App App--stats"><StatsLoginController /></div>;
	}
	
	/* Game section */
	let GameComponent = Loading;
	if (!isPreloadingImages) GameComponent = GameController;
	return (
		<div className={'App ' + deviceInfo.orientation}>
			<div id="appWrap" className="App-wrap">
				<GameComponent 
					handleFullscreen={handleFullscreen} 
					deviceInfo={deviceInfo}
				/>
				<ImageLoader 
					preloadCallback={preloadCallback} 
					imagesDataInitial={imagesDataInitial}
					imagesData={imagesData}
					videoData={videoData}
				/>
			</div>
		</div>
	);
};

export default App;
