import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import Button from 'components/button/button';
import { introUiTexts, cookieUiTexts } from 'data/ui-texts';
import './intro.scss';

const Intro = ({cookieConsent, isStopped, isStartingGame, startGame, toggleIsPaused}) => {

	const [isCookieAcceptedPopup, setIsCookieAcceptedPopup] = useState(false);

	/**
	 * handle start game
	 */
	const handleStartGame = () => {
		/* check if OK to cookies */
		if (!cookieConsent) {
			setIsCookieAcceptedPopup(true);
			return;
		}
		/* start game */
		startGame();
	};



	return (
		<div className="Intro">
			<div className="Intro-banner" >
				<div className="Intro-title"><span>{introUiTexts.title}</span></div>
				<div className='Intro-subtitle'><span>{introUiTexts.subtitle}</span></div>
			</div>
			<div className="Intro-content">
				<div className="Intro-text"><span>{introUiTexts.text}</span></div>
				{cookieConsent && <div className="Intro-buttons">
					<Button 
						isLoading={isStartingGame} 
						classes={['startButton']} 
						text={introUiTexts.start} 
						onClick={() => {handleStartGame();}} 
					/>
					<div className={`Intro-audioIcon ${isStopped ? 'isMuted' : ''}`}
						onClick={() => {toggleIsPaused(!isStopped, !isStopped);}}>	
					</div>
				</div>}
			</div>
			<div className='Intro-logo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}} ></div>
			{isCookieAcceptedPopup && <div className='Intro-cookiePopupWrapper' 
				onClick={() => {setIsCookieAcceptedPopup(false);}}>
				<div className='Intro-cookiePopup' onClick={(e) => {e.stopPropagation();}}>
					<span>{cookieUiTexts.popupText}</span>
				</div>
			</div>}
			<a 
				className="Intro-availabilityStatementLink" 
				href={appConfig.availabilityStatementLink} 
				target="_blank" 
				rel="noopener noreferrer">
				{introUiTexts.availabilityStatement}
			</a>
		</div>
	);
};

Intro.propTypes = {
	isStopped: PropTypes.bool.isRequired,
	isStartingGame: PropTypes.bool.isRequired,
	startGame: PropTypes.func.isRequired,
	toggleIsPaused: PropTypes.func.isRequired,
	cookieConsent: PropTypes.bool.isRequired,
};

export default Intro;