import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {outroUiTexts, verdictUiTexts} from 'data/ui-texts';
import './outro.scss';
import Button from 'components/button/button';

const Outro = ({goToPage, playerData, getStatsCollection, playClick, resetPlayerData}) => {
	/* verdict options */
	const options = [1, 2, 3, 4, 5];
	const [counts, setCounts] = useState([]);
	/**
	 * onmount fetch verdicts from DB
	 */
	useEffect(()=>{
		getVerdict();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * fetchs verdicts already in DB and calculates % for each option
	 */
	const getVerdict = () => {
		getStatsCollection().then((response) =>{
			if (response.status === 'ok') {
				let counter = [];
				for (const option of options) {
					counter.push(((response.stats.reduce((counter, obj) => {
						// eslint-disable-next-line no-param-reassign
						return obj.verdict === option ? counter += 1 : counter;
					}, 0) / response.stats.length ) * 100).toFixed(0)
					); 
				}
				setCounts(counter);
			}
		},
		(error) => {
			console.error(error);
		});
			
		
	};
	/* checks if the players verdict is the same as the real */
	let real = 1;
	let isEqual = false;
	if (real === playerData.verdict) {
		isEqual = true;
	}
	return (
		<div className="Outro">
			<div className="Outro-textbox">
				<div className='Outro-content'> 
					<div className='Outro-line'></div>
					{options.map((option) => {
						let classPlayerChoice = '';
						isEqual ? classPlayerChoice = 'real' : classPlayerChoice = 'current';
						return <div className='Outro-options' key={option}>
							<div className={`Outro-optionText 
							${'option-' + option}`}>
								<span>{verdictUiTexts['verdict' + option]}	</span>
							</div>
							<div className={`Outro-optionOthers ${'option-' + option} 
						${option === playerData.verdict ? classPlayerChoice : ''} ${option === real ? 'real' : ''}`}>
								<span>{counts.length !== 0 ? counts[option - 1] + '%' : '...'}</span>
							</div>
						</div>;
					})}
					<div className='Outro-labels'>
						<div className={`Outro-labelsYou ${isEqual ? 'real' : 'current'}`}></div>
						<span>{isEqual ? (outroUiTexts.outroLabelOne + ' / ' + outroUiTexts.outroLabelThree) 
							: outroUiTexts.outroLabelOne}</span>
						
						<div className={`${isEqual ? '' : 'Outro-labelsReal'}`}></div>
						<span>{isEqual ? '' : outroUiTexts.outroLabelThree}</span>
						<div className='Outro-labelsOthers'></div>
						<span>{outroUiTexts.outroLabelTwo}</span>
					</div>
					<div className='Outro-text'><span>{outroUiTexts.text}</span></div>
				</div> 
				
			</div>
			
			<Button 
				text={outroUiTexts.denmarkCourts} 
				classes={['becomeJudge']} 
				onClick={() => {window.parent.open('http://danmarksdomstole.dk/');}}
			/>
			<Button 
				text={outroUiTexts.Domsdatabasen}
				classes={['goToStart']} 
				onClick={() => {window.parent.open('https://domsdatabasen.dk/');}}
			/>
			<Button 
				text={outroUiTexts.outtroBecomeJudge}
				classes={['becomeJudge2']} 
				onClick={() => {window.parent.open('https://www.domstol.dk/til-dig-der-er/laegdommer/');}}
			/>
			<Button classes={['prev']} onClick={() => {playClick();goToPage('departure-room');}} />
			<Button classes={['next']} onClick={() => {playClick();resetPlayerData(true);}} />
		</div>
	);
};

Outro.propTypes = {
	goToPage: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
	getStatsCollection: PropTypes.func.isRequired,
	playClick:  PropTypes.func.isRequired,
	resetPlayerData: PropTypes.func.isRequired,
};

export default Outro;