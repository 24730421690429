import React from 'react';
import PropTypes from 'prop-types';
import {departureUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './departure.scss';

const Departure = ({goToPage, playClick}) => {

	return (
		<div className="Departure">
			<div className="Departure-textbox">
				<div className="Departure-title"><span>{departureUiTexts.title}</span></div>
				<div className="Departure-text"><span>{departureUiTexts.text}</span></div>
			</div>
			<Button classes={['prev']} onClick={() => {playClick();goToPage('court-room');}} />
			<Button classes={['next']} onClick={() => {playClick();goToPage('outro');}} />
		</div>
	);
};

Departure.propTypes = {
	goToPage: PropTypes.func.isRequired,
	playClick:PropTypes.func.isRequired,
};

export default Departure;