const audioData = [
	{
		// Anklager 1
		page: 'court-room',
		textIndex: 0,
		popupIndex: 2,
		tabId: 1,
		audioStart: 0, // seconds * 30
		audioDuration: 468, // seconds * 30
		audioDelay: 2000, // miliseconds 
	},
	{
		// Anklager 2
		page: 'court-room',
		textIndex: 1,
		popupIndex: 2,
		tabId: 1,
		audioStart: 540,
		audioDuration: 298,
		audioDelay: 500,
	},
	{
		// Anklager 3
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 1,
		audioStart: 903,
		audioDuration: 115,
		audioDelay: 3000
	},
	{
		// Anklager 4
		page: 'court-room',
		textIndex: 1,
		popupIndex: 5,
		tabId: 4,
		audioStart: 1093,
		audioDuration: 45,
		audioDelay: 0
	},
	{
		// Anklager 5
		page: 'court-room',
		textIndex: 0,
		popupIndex: 6,
		tabId: 3,
		audioStart: 1210,
		audioDuration: 108,
		audioDelay: 3000 // 500
	},
	{
		// Anklager 6
		page: 'court-room',
		textIndex: 0,
		popupIndex: 7,
		tabId: 2,
		audioStart: 1386,
		audioDuration: 412,
		audioDelay: 500
	},
		{
		// Anklager 7
		page: 'court-room',
		textIndex: 1,
		popupIndex: 8,
		tabId: 1,
		audioStart: 1878,
		audioDuration: 100,
		audioDelay: 500
	},
	{
		// Anklager 8
		page: 'court-room',
		textIndex: 1,
		popupIndex: 9,
		tabId: 1,
		audioStart: 2037,
		audioDuration: 151,
		audioDelay: 500
	},
	{
		// Anklager 9
		page: 'court-room',
		textIndex: 2,
		popupIndex: 9,
		tabId: 1,
		audioStart: 2256,
		audioDuration: 231,
		audioDelay: 500
	},
	{
		// Anklager 10
		page: 'court-room',
		textIndex: 3,
		popupIndex: 9,
		tabId: 1,
		audioStart: 2574,
		audioDuration: 424,
		audioDelay: 500
	},
	{
		// Anklager 11
		page: 'court-room',
		textIndex: 4,
		popupIndex: 9,
		tabId: 1,
		audioStart: 3114,
		audioDuration: 454,
		audioDelay: 500
	},
	{
		// Anklager 12
		page: 'court-room',
		textIndex: 5,
		popupIndex: 9,
		tabId: 1,
		audioStart: 3649,
		audioDuration: 218,
		audioDelay: 500
	},
	{
		// Anklager 13
		page: 'court-room',
		textIndex: 6,
		popupIndex: 9,
		tabId: 1,
		audioStart: 3947,
		audioDuration: 431,
		audioDelay: 500
	},
	{
		// Anklager 14
		page: 'court-room',
		textIndex: 7,
		popupIndex: 9,
		tabId: 1,
		audioStart: 4458,
		audioDuration: 280,
		audioDelay: 500
	},
	{
		// Dommer 1-2 
		page: 'pre-court',
		textIndex: 1,
		preCourtAnswer: 0,
		audioStart: 4800,
		audioDuration: 118,
		audioDelay: 1000,
		nextPage: 'court-room'
	},
	{
		// Dommer 1
		page: 'pre-court',
		textIndex: 0,
		audioStart: 5002,
		audioDuration: 666,
		audioDelay: 1000,
		nextPage: 'pre-court',
		nextTextIndex: 1
	},
	{
		// Dommer 2
		page: 'pre-court',
		textIndex: 1,
		preCourtAnswer: 1,
		audioStart: 5757,
		audioDuration: 571,
		audioDelay: 1000,
		nextPage: 'intro'
	},
	{
		// Dommer 3
		page: 'court-room',
		textIndex: 0,
		popupIndex: 1,
		tabId: 1,
		audioStart: 6389,
		audioDuration: 239,
		audioDelay: 3000
	},
	{
		// Dommer 4
		page: 'court-room',
		textIndex: 2,
		popupIndex: 2,
		tabId: 1,
		audioStart: 6694,
		audioDuration: 84,
		audioDelay: 0
	},
	{
		// Dommer 5
		page: 'court-room',
		textIndex: 0,
		popupIndex: 4,
		tabId: 1,
		audioStart: 6852,
		audioDuration: 226,
		audioDelay: 2250
	},
	{
		// Dommer 6
		page: 'court-room',
		textIndex: 2,
		popupIndex: 5,
		tabId: 4,
		audioStart: 7144,
		audioDuration: 54,
		audioDelay: 0
	},
	{
		// Dommer 7
		page: 'court-room',
		textIndex: 0,
		popupIndex: 6,
		tabId: 1,
		audioStart: 7278,
		audioDuration: 220,
		audioDelay: 1500
	},
	{
		// Dommer 8
		page: 'court-room',
		textIndex: 0,
		popupIndex: 6,
		tabId: 2,
		audioStart: 7567,
		audioDuration: 290,
		audioDelay: 500
	},
	{
		// Dommer 9
		page: 'court-room',
		textIndex: 0,
		popupIndex: 6,
		tabId: 7,
		audioStart: 7918,
		audioDuration: 151,
		audioDelay: 500
	},
	{
		// Dommer 10
		page: 'court-room',
		textIndex: 0,
		popupIndex: 7,
		tabId: 1,
		audioStart: 8126,
		audioDuration: 92,
		audioDelay: 500
	},
	{
		// Dommer 11
		page: 'court-room',
		textIndex: 0,
		popupIndex: 8,
		tabId: 1,
		audioStart: 8280,
		audioDuration: 118,
		audioDelay: 1000
	},
	{
		// Dommer 12
		page: 'court-room',
		textIndex: 0,
		popupIndex: 9,
		tabId: 1,
		audioStart: 8475,
		audioDuration: 223,
		audioDelay: 2000
	},
	{
		// Dommer 13
		page: 'court-room',
		textIndex: 0,
		popupIndex: 11,
		tabId: 1,
		audioStart: 8765,
		audioDuration: 113,
		audioDelay: 500
	},
	{
		// Dommer 14
		page: 'court-room',
		textIndex: 0,
		popupIndex: 11,
		tabId: 3,
		audioStart: 8958,
		audioDuration: 160,
		audioDelay: 500
	},
	{
		// Dommer 15
		page: 'court-room',
		textIndex: 0,
		popupIndex: 12,
		tabId: 1,
		audioStart: 9173,
		audioDuration: 425,
		audioDelay: 4000
	},
	{
		// Dommer 16
		page: 'court-room',
		textIndex: 1,
		popupIndex: 12,
		tabId: 1,
		audioStart: 9670,
		audioDuration: 528,
		audioDelay: 500
	},
	{
		// Dommer 17
		page: 'court-room',
		textIndex: 2,
		popupIndex: 12,
		tabId: 1,
		audioStart: 10258,
		audioDuration: 300,
		audioDelay: 500
	},
	{
		// Dommer 18
		page: 'court-room',
		textIndex: 3,
		popupIndex: 12,
		tabId: 1,
		audioStart: 10619,
		audioDuration: 299,
		audioDelay: 500
	},
	{
		// Dommer 19
		page: 'court-room',
		textIndex: 4,
		popupIndex: 12,
		tabId: 1,
		audioStart: 11002,
		audioDuration: 276,
		audioDelay: 500
	},
	{
		// Dommer 20
		page: 'court-room',
		textIndex: 5,
		popupIndex: 12,
		tabId: 1,
		audioStart: 11328,
		audioDuration: 190,
		audioDelay: 500
	},
	{
		// Dommer 21
		page: 'court-room',
		textIndex: 6,
		popupIndex: 12,
		tabId: 1,
		audioStart: 11555,
		audioDuration: 263,
		audioDelay: 500
	},
	{
		// Dommer 22
		page: 'court-room',
		textIndex: 7,
		popupIndex: 12,
		tabId: 1,
		audioStart: 11893,
		audioDuration: 165,
		audioDelay: 500
	},
	{
		// Dommer 24 // TODO dommer 26
		page: 'court-room',
		textIndex: 0,
		popupIndex: 13,
		tabId: 3,
		playerAnswer: 1,
		audioStart: 12120,
		audioDuration: 447,
		audioDelay: 0
	},
	{
		// Dommer 25-1
		page: 'court-room',
		textIndex: 1,
		popupIndex: 13,
		tabId: 3,
		playerAnswer: 1,
		audioStart: 12632,
		audioDuration: 204,
		audioDelay: 0
	},
	{
		// Dommer 25-2
		page: 'court-room',
		textIndex: 2,
		popupIndex: 13,
		tabId: 3,
		playerAnswer: 1,
		audioStart: 12897,
		audioDuration: 64,
		audioDelay: 0
	},
	{
		// Dommer 26 // todo dommer 25
		page: 'court-room',
		textIndex: 0,
		popupIndex: 13,
		tabId: 3,
		playerAnswer: 2,
		audioStart: 13047,
		audioDuration: 422,
		audioDelay: 0
	},
	{
		// Dommer 27-1
		page: 'court-room',
		textIndex: 1,
		popupIndex: 13,
		tabId: 3,
		playerAnswer: 2,
		audioStart: 13540,
		audioDuration: 166,
		audioDelay: 0
	},
	{
		// Dommer 27-2
		page: 'court-room',
		textIndex: 2,
		popupIndex: 13,
		tabId: 3,
		playerAnswer: 2,
		audioStart: 13775,
		audioDuration: 81,
		audioDelay: 0
	},
	{
		// Dommer 28
		page: 'court-room',
		textIndex: 0,
		popupIndex: 15,
		tabId: 1,
		audioStart: 13922,
		audioDuration: 264,
		audioDelay: 3000
	},
	{
		// Dommer 29
		page: 'court-room',
		textIndex: 1,
		popupIndex: 15,
		tabId: 1,
		audioStart: 14235,
		audioDuration: 431,
		audioDelay: 500
	},
	{
		// Domsmand 1
		page: 'court-room',
		textIndex: 0,
		popupIndex: 13,
		tabId: 1,
		audioStart: 14731,
		audioDuration: 445,
		audioDelay: 500
	},
	{
		// Domsmand 2
		page: 'court-room',
		textIndex: 1,
		popupIndex: 13,
		tabId: 1,
		audioStart: 15261,
		audioDuration: 395,
		audioDelay: 500
	},
	{
		// Forsvarer 1
		page: 'court-room',
		textIndex: 0,
		popupIndex: 3,
		tabId: 1,
		audioStart: 15721,
		audioDuration: 355,
		audioDelay: 2000
	},
	{
		// Forsvarer 2
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 5,
		audioStart: 16149,
		audioDuration: 167,
		audioDelay: 0
	},
	{
		// Forsvarer 3
		page: 'court-room',
		textIndex: 0,
		popupIndex: 6,
		tabId: 5,
		audioStart: 16389,
		audioDuration: 71,
		audioDelay: 500
	},
	{
		// Forsvarer 4
		page: 'court-room',
		textIndex: 2,
		popupIndex: 8,
		tabId: 1,
		audioStart: 16493,
		audioDuration: 66,
		audioDelay: 500
	},
	{
		// Forsvarer 5
		page: 'court-room',
		textIndex: 0,
		popupIndex: 10,
		tabId: 1,
		audioStart: 16631,
		audioDuration: 468,
		audioDelay: 500
	},
	{
		// Forsvarer 6
		page: 'court-room',
		textIndex: 1,
		popupIndex: 10,
		tabId: 1,
		audioStart: 17160,
		audioDuration: 662,
		audioDelay: 500
	},
	{
		// Forsvarer 7
		page: 'court-room',
		textIndex: 2,
		popupIndex: 10,
		tabId: 1,
		audioStart: 17886,
		audioDuration: 324,
		audioDelay: 500
	},
	{
		// Fortæller 1
		page: 'arrival',
		textIndex: 0,
		audioStart: 18275,
		audioDuration: 114,
		audioDelay: 500,
		nextPage: 'pre-court'
	},
	{
		// Tiltalte 1
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 2,
		audioStart: 18476,
		audioDuration: 244,
		audioDelay: 500
	},
	{
		// Tiltalte 2
		page: 'court-room',
		textIndex: 1,
		popupIndex: 5,
		tabId: 2,
		audioStart: 18788,
		audioDuration: 201,
		audioDelay: 500
	},
	{
		// Tiltalte 3
		page: 'court-room',
		textIndex: 2,
		popupIndex: 5,
		tabId: 2,
		audioStart: 19061,
		audioDuration: 178,
		audioDelay: 500
	},
	{
		// Tiltalte 4
		page: 'court-room',
		textIndex: 3,
		popupIndex: 5,
		tabId: 2,
		audioStart: 19380,
		audioDuration: 139,
		audioDelay: 500
	},
	{
		// Tiltalte 5
		page: 'court-room',
		textIndex: 4,
		popupIndex: 5,
		tabId: 2,
		audioStart: 19642,
		audioDuration: 215,
		audioDelay: 500
	},
	{
		// Tiltalte 6
		page: 'court-room',
		textIndex: 5,
		popupIndex: 5,
		tabId: 2,
		audioStart: 19923,
		audioDuration: 203,
		audioDelay: 500
	},
	{
		// Tiltalte 7
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 4,
		playerAnswer: 1,
		audioStart: 20181,
		audioDuration: 246,
		audioDelay: 0
	},
	{
		// Tiltalte 8
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 4,
		playerAnswer: 2,
		audioStart: 20514,
		audioDuration: 393,
		audioDelay: 0,
	},
	{
		// Tiltalte 9
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 6,
		audioStart: 20998,
		audioDuration: 209,
		audioDelay: 0
	},
	{
		// Tiltalte 10
		page: 'court-room',
		textIndex: 1,
		popupIndex: 5,
		tabId: 6,
		audioStart: 21377,
		audioDuration: 102,
		audioDelay: 0
	},
	{
		// Tiltalte 11
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 8,
		playerAnswer: 3,
		audioStart: 21631,
		audioDuration: 418,
		audioDelay: 0
	},
	{
		// Tiltalte 12
		page: 'court-room',
		textIndex: 0,
		popupIndex: 5,
		tabId: 8,
		playerAnswer: 4,
		audioStart: 22122,
		audioDuration: 257,
		audioDelay: 0
	},
	{
		// Tiltalte 13
		page: 'court-room',
		textIndex: 0,
		popupIndex: 11,
		tabId: 2,
		audioStart: 22467,
		audioDuration: 393,
		audioDelay: 500
	},
	{
		// Vidne 1
		page: 'court-room',
		textIndex: 0,
		popupIndex: 6,
		tabId: 4,
		audioStart: 22937,
		audioDuration: 342,
		audioDelay: 500
	},
	{
		// Vidne 2
		page: 'court-room',
		textIndex: 1,
		popupIndex: 6,
		tabId: 4,
		audioStart: 23340,
		audioDuration: 175,
		audioDelay: 500
	},
	{
		// Vidne 3
		page: 'court-room',
		textIndex: 2,
		popupIndex: 6,
		tabId: 4,
		audioStart: 23580,
		audioDuration: 242,
		audioDelay: 500
	},
	{
		// Vidne 4
		page: 'court-room',
		textIndex: 0,
		popupIndex: 6,
		tabId: 6,
		audioStart: 23880,
		audioDuration: 266,
		audioDelay: 500
	},
	{
		// Vidne 5
		page: 'court-room',
		textIndex: 1,
		popupIndex: 6,
		tabId: 6,
		audioStart: 24300,
		audioDuration: 236,
		audioDelay: 500
	}
];

module.exports = {
	audioData
};
