import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {getTextAndQuestionData} from 'helpers/game-helper';
import renderMarkdown from 'helpers/text-helper';
import {generalUiTexts} from 'data/ui-texts';
import {popupData} from 'data/popup-data';
import Button from 'components/button/button';
import Carousel, {CarouselItem} from 'components/carousel/carousel';
import './popup.scss';

const Popup = (props) => {
	let {
		onBack,
		popupRef,
		popup,
		playerData,
		updatePlayerData,
		completeRoom,
		listOfComplete, 
		selectedTabId,
		setSelectedTabId,
		popupIndex,
		flowAnimation,
		goToTextIndex,
		textIndex,
		setShowButtonAnimation,
		showButtonAnimation,
		handleNavigate,
		nextButtonClick,
		playClick
	} = props;


	/* Animation name */
	const [animationName, setAnimationName] = useState('');

	/* Timeout ref */
	const timerRef = useRef(null);	

	/* Popup data */
	const showPopup = popup.show;
	const type = popup.type;
	const classes = [type];
	const title = popupData[type].title;
	const texts = popupData[type].texts;
	const videos = popupData[type].videos;
	const tabs = popupData[type].tabs;
	const questions = popupData[type].questions;
	const hasBackButton = popupData[type].hasBackButton;
	const textQuestionAndAudioData = getTextAndQuestionData(popup, selectedTabId, playerData);

	/* Classes */
	let className = 'Popup-container';
	if (textQuestionAndAudioData.className) className += textQuestionAndAudioData.className;
	if (classes && classes.length > 0) {
		if (tabs) {
			classes.forEach((c) => {className = className + ' ' + c + '-' + selectedTabId;});
		} else {
			classes.forEach((c) => {className = className + ' ' + c;});
		}
	}

	/** checks if currernt popup is complete aka all possble text and tabs have been viewed */
	const checkComplete = () => {
		// already complete
		if (listOfComplete.includes(type)) {
			return;
		}
		/* set complete true when last page has been viewed without tabs */
		if (texts && !tabs) {
			let nrOfDocuments = texts.filter((text) => {return tabs ? text.tabId === selectedTabId : true;}).length;
			if (textIndex >= nrOfDocuments - 1 && !tabs) completeRoom(type);
		}

		/* check for all tabs if the text has been seen */
		if (tabs && texts) {
			let nrOfDocuments = texts.filter((text) => {return tabs ? text.tabId === selectedTabId : true;}).length;
			if (textIndex >= nrOfDocuments - 1 && tabs.length >= selectedTabId) {
				tabs[selectedTabId - 1].complete = true;
				if (Object.keys(tabs).every((tab) => { return tabs[tab].complete === true;})) {
					completeRoom(type);
				}
			}
		}
	};

	/** Select question */
	const selectQuestion = (question) => {
		
		let questionsAsked = JSON.parse(JSON.stringify(playerData)).questionsAsked;
		let unwrap = ({tabId, id}) => {return {tabId, id};};
		
		let newQuestion = unwrap(JSON.parse(JSON.stringify(question)));
		
		newQuestion.type = type;
		if (questionsAsked.length !== 0) {
			let objIndex = questionsAsked.findIndex(
				(obj) => {return obj.tabId === newQuestion.tabId && obj.type === newQuestion.type;});
			if (objIndex !== -1) questionsAsked[objIndex].id = newQuestion.id;
			if (objIndex === -1) questionsAsked.push(newQuestion);
		} else {
			questionsAsked.push(newQuestion);
		}
		updatePlayerData({questionsAsked});
	};

	/* Handle animations */
	useEffect(() => {
		if (timerRef) {
			clearTimeout(timerRef.current);
			setAnimationName('placeholder');
		}
		if (flowAnimation.length > 0) {
			if (flowAnimation === 'animation-welcome' || flowAnimation === 'animation-witnesses-1' 
				|| flowAnimation === 'animation-defendant-1') {
				setAnimationName(flowAnimation);
				timerRef.current = setTimeout(()=>{
					setAnimationName('placeholder');
				}, 10000);
			} else {
				setAnimationName(flowAnimation);
				timerRef.current = setTimeout(()=>{
					setAnimationName('placeholder');
				}, 3000);
			}			
		}
	}, [flowAnimation]);

	/** If the popup type or the tab are changed, show the first tab and the first text */
	useEffect(() => {
		setSelectedTabId(1, 0);
		goToTextIndex(0);
		if ((texts && texts.length === 1) || (tabs && selectedTabId === 1)) checkComplete();
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);
	

	/** Auto-nav to first text when switching tab */
	useEffect(() => {
		goToTextIndex(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabId]);

	/** check if complete on all types of changes made by the popups */
	useEffect(() => {
		checkComplete();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabId, textIndex, textQuestionAndAudioData.question]);


	/** Component did mount / will unmount */
	useEffect(() => {
		return (()=>{
			// Reset
			setSelectedTabId(1, 0);
			goToTextIndex(0);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	
	return (
		<div className={'Popup ' + (showPopup ? 'show' : '')}>
			<div className={className + ' ' + (animationName ? ' ' + animationName : '')}>
				{textQuestionAndAudioData.question ? '' : <div className="Popup-header">
					{tabs && <div className="Popup-tabs">
						{tabs.map((tab) => {
							return (
								<div
									key={tab.id}
									className={'Popup-title'}>
									<span>{selectedTabId === tab.id ? tab.title : ''}</span>
									<span className='Popup-subtitle'> 
										{(selectedTabId === tab.id && tab.subtitle) ? tab.subtitle : ''}
									</span>
								</div>
							);
						})}
					</div>}
				
					{/* Title */}
					{title && <div className="Popup-title"><span>{title}</span></div>}
					{(
						title === null && 
						!tabs && 
						type !== 'empty' && 
						textIndex < textQuestionAndAudioData.availableTexts.length
					) 
					&& <div className="Popup-title">
						<span>{textQuestionAndAudioData.availableTexts[textIndex].title}</span>
					</div>}
		
					{/* Go Back */}
					{hasBackButton && 
					<div className="Popup-back" onClick={onBack}>{generalUiTexts.goBack}</div>
					}

				</div>}
				
				{(textQuestionAndAudioData.chosenQuestion && type === 'talkToJudge') && <div className="Popup-header"> 
					{tabs && <div className="Popup-tabs">
						<div className={'Popup-title'}>
							<span>{(textQuestionAndAudioData.chosenQuestion 
								? textQuestionAndAudioData.chosenQuestion.prev : '')}</span>
						</div>
					</div>}
				</div>}

				{(textQuestionAndAudioData.chosenQuestion && type === 'defendant') && <div className="Popup-header"> 
					{tabs && <div className="Popup-tabs">
						<div className={'Popup-title'}>
							<span>{(textQuestionAndAudioData.chosenQuestion 
								? textQuestionAndAudioData.chosenQuestion.prev : '')}</span>
						</div>
					</div>}
				</div>}


					
				<div className="Popup-body">
					{/* Texts */}
					{texts && <div ref={popupRef} className="Popup-texts">
						<Carousel 
							goToTextIndex={goToTextIndex} 
							activeIndex={textIndex} 
							amountOfTexts={textQuestionAndAudioData.amountOfTexts} 
							isNextText={showButtonAnimation}>
							{textQuestionAndAudioData.availableTexts.map((text) => {
								return <CarouselItem key={text.id}>
									<div className="Popup-text">{text.text ? renderMarkdown(text.text) : ''}</div>
								</CarouselItem>;
							})}
						</Carousel>
					</div>}

					{/* Questions */}
					{questions && <div ref={popupRef} className="Popup-questions">
						{textQuestionAndAudioData.availableQuestions.map((question) => {
							return <div
								key={question.id}
								className="Popup-question">
								
								<div className="Popup-question-title">
									<span>{question.title ? question.title : ''}</span>
								</div>
								<span>{question.text}</span>
								<div className={'Popup-questionButton ' + 
								(showButtonAnimation ? 'blink' : '')
									+ (textQuestionAndAudioData.chosenQuestion && 
											(question.id === textQuestionAndAudioData.chosenQuestion.id) 
										? 'chosen' : '')}
								onClick={() => {
									selectQuestion(question);
									setSelectedTabId(selectedTabId + 1);
								}}>
									<span>
										{(textQuestionAndAudioData.chosenQuestion && 
										(question.id === textQuestionAndAudioData.chosenQuestion.id)) ?
											generalUiTexts.chosen : generalUiTexts.choose}
									</span>
								</div>
							</div>;
						})}
					</div>}

					{/* Video */}
					{videos && <div className={'Popup-video'}>
						{videos.find((vid) => {return vid.tabId === selectedTabId;}) &&
						<video width={'100%'} height={'100%'} autoPlay onEnded={()=>{
							setTimeout(() => {handleNavigate(popupIndex + 1);}, 1000);
							
						}}>
							<source src={videos.find((vid) => {return vid.tabId === selectedTabId;}).src} 
								type="video/mp4"></source>
						</video>}
					</div>}
				</div>
			</div>

			{tabs && selectedTabId !== tabs.length && <Button
				classes={['next popup', `${(textQuestionAndAudioData.amountOfTexts !== 0 
					&& textIndex + 1 !== textQuestionAndAudioData.amountOfTexts 
					&& !JSON.parse(JSON.stringify(playerData.courtRoomComplete)).includes(type)) ? 'disabled' : ''} 
					${textQuestionAndAudioData.availableQuestions.length !== 0 && 
						textQuestionAndAudioData.question === null ? 'disabled' : ''} `]} 
				onClick={() => {

					if (textQuestionAndAudioData.amountOfTexts !== 0 &&
						textIndex + 1 !== textQuestionAndAudioData.amountOfTexts 
						&& !JSON.parse(JSON.stringify(playerData.courtRoomComplete)).includes(type)) {
						setShowButtonAnimation(true);
						return;
					}				
					playClick();
					nextButtonClick(textQuestionAndAudioData);					
				}}
			/> }
			{tabs && selectedTabId !== 1 && <Button 
				classes={['prev popup']} 
				onClick={() => {
					playClick();
					setSelectedTabId(
						selectedTabId === 1 ? selectedTabId : selectedTabId - 1,
						selectedTabId === 1 ? selectedTabId + 1 : selectedTabId
					);
				}}
			/>}
		</div>
		
	);
};

Popup.defaultProps = {
	title: null,
	texts: null,
	tabs: null,
	videos: null,
	questions: null
};

Popup.propTypes = {
	onBack: PropTypes.func,
	popupRef: PropTypes.object,
	popup: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	completeRoom: PropTypes.func.isRequired,
	listOfComplete: PropTypes.array.isRequired,
	selectedTabId:PropTypes.number.isRequired, 
	setSelectedTabId: PropTypes.func.isRequired,
	popupIndex: PropTypes.number.isRequired, 
	flowAnimation: PropTypes.string.isRequired,
	goToTextIndex: PropTypes.func.isRequired,
	textIndex: PropTypes.number.isRequired, 
	setShowButtonAnimation: PropTypes.func.isRequired,
	showButtonAnimation: PropTypes.bool.isRequired,
	handleNavigate: PropTypes.func.isRequired,
	nextButtonClick: PropTypes.func.isRequired,
	playClick: PropTypes.func.isRequired
};

export default Popup;