import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

const Button = ({isLoading, isDisabled, type, text, classes, onClick}) => {
	/**
	 * Handle button click
	 * @param {object} event 
	 */
	const handleClick = (event) => {
		event.preventDefault();
		if (isDisabled || !onClick) return;
		onClick(event);		
	};

	/* Classes */
	let className = 'Button';
	if (isLoading) {className += ' loading';}
	if (isDisabled) {className += ' disabled';}
	if (classes && classes.length > 0) {
		classes.forEach((c) => {className = className + ' ' + c;});
	}

	if (onClick) {
		return <div className={className} onClick={handleClick}><span>{text}</span></div>;
	}

	return null;

};


Button.defaultProps = {
	isLoading: false,
	isDisabled: false,
	isChecked: false,
	classes: null
};

Button.propTypes = {
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	type: PropTypes.string,
	text: PropTypes.string,
	classes: PropTypes.array,
	onClick: PropTypes.func,

};


export default Button;