import {popupData, courtRoomOrder, votingRoomOrder } from 'data/popup-data';

/**
 * Show room animation
 * @param {object} playerData 
 * @param {string} curr 
 * @returns 
 */
function checkIfShowAnimation(playerData, curr) {
	if (!playerData.courtRoomComplete.includes(curr)) {
		return true;
	} 
	return false;
};

/**
 * Get available texts and question for current page / popup / tab
 * @param {object} popup 
 * @param {number} selectedTabId 
 * @param {object} playerData 
 * @returns 
 */
function getTextAndQuestionData(popup, selectedTabId, playerData) {
	let texts = popupData[popup.type].texts;
	let tabs = popupData[popup.type].tabs;
	let questions = popupData[popup.type].questions;
	let className = '';
	/** Get texts and questions belonging to a tab. If no tabs, get all*/
	let availableTexts = (texts 
		? texts.filter((text) => {
			return (tabs ? text.tabId === selectedTabId : true);
		}) 
		: []
	);

	let availableQuestions = questions
		? questions.filter((question) => {return tabs ? question.tabId === selectedTabId : true;})
		: [];
		
	/** Get cached question if it has already been asked */
	let question = null;
	let chosenQuestion = null;
	if (playerData.questionsAsked.find((q) => {return (q.tabId === selectedTabId && q.type === popup.type);})) {
		question = playerData.questionsAsked.find((q) => {
			return (q.tabId === selectedTabId && q.type === popup.type);
		});
		chosenQuestion = questions.find((q) => {
			return question.id === q.id;
		});
	}

	/** if a question has been asked in previous question */
	if (playerData.questionsAsked.find((q) => {return (q.tabId === selectedTabId - 1 && q.type === popup.type);})) {
		let currentQuestion = playerData.questionsAsked.find(
			(q) => {return q.tabId === selectedTabId - 1;});

		let answers = questions.find((q) => {
			return currentQuestion.id === q.id;
		}).answer.split('<$$$>');

		if ((availableTexts.length !== answers.length && popup.type === 'defendant')) {
			if (texts[texts.length - 1].id !== 15 && texts[texts.length - 2].id !== 13) {
				texts.push({
					id: 13,
					tabId: 4,
					text: null,
				});
				texts.push({
					id: 15,
					tabId: 4,
					text: null,
				});
				availableTexts.push({
					id: 13,
					tabId: 4,
					text: null,
				});
				availableTexts.push({
					id: 15,
					tabId: 4,
					text: null,
				});
			}
		}

		availableTexts.map((text, index) => {
			return text.text = answers[index];
		});
		className += ' answer' + playerData.questionsAsked.find(
			(q) => {return q.tabId === selectedTabId - 1;}).id;

	}

	let amountOfTexts = null;
	if (texts && texts.length !== 0) {
		if (tabs) {
			amountOfTexts = texts.filter((text) => {return text.tabId === selectedTabId;}).length;
		} else {
			amountOfTexts = texts.length;
		}
	};




	return {
		availableTexts,
		question,
		availableQuestions,
		chosenQuestion,
		amountOfTexts,
		className
	};
};


function getNavData(index, currentPopupIndex, playerData) {
	/* Is allowed voting / verdict? */
	const isAllowedVoting = playerData.courtRoomComplete.includes('defendant2');
	const isAllowVerdict = playerData.courtRoomComplete.includes('talkToJudge');

	/* Get order of popups */
	let popupsOrder = JSON.parse(JSON.stringify(courtRoomOrder.concat(votingRoomOrder)));

	/* Rooms to be completed before voting is allowed */
	const completeBeforeVoting = courtRoomOrder.slice(1).slice(0, -1); // remove head and tail

	/* Default values */
	let prevPopupIndex = currentPopupIndex;
	let newPopupIndex = index;
	let popupData = null;
	let showAnimation = false;
	let showTip = false;
	let showLegalFramework = false;
	let showVerdict = false;
	let completedRoomId = null;

	/* Default popup - before voting */
	if (index <= completeBeforeVoting.length) {
		popupData = {show: true, type: popupsOrder[index]};
		showAnimation = checkIfShowAnimation(playerData, popupsOrder[index]);
	}
	if (index === completeBeforeVoting.length + 1) {
		/* Player tried to navigate to 'voting' */
		if (isAllowedVoting || playerData.verdict) {
			/* Allowed: Show legal framework popup */
			showAnimation = checkIfShowAnimation(playerData, popupsOrder[index]);
			showLegalFramework = true;

		} else {
			/* Not allowed: Show default and hint popup */
			prevPopupIndex = currentPopupIndex - 1;
			newPopupIndex = completeBeforeVoting.length;
			popupData = {show: true, type: popupsOrder[currentPopupIndex]};
			showTip = true;
		}
	} else {
		/* Player tried to navigate beyond 'voting' */
		if (index < popupsOrder.length - 2) {
			/* Voting before verdict */
			popupData = {show: true, type: popupsOrder[index]};
			showAnimation = checkIfShowAnimation(playerData, popupsOrder[index]);
		}
		
		/* Verdict */
		if (
			index === popupsOrder.length - 2 && 
			(
				(isAllowVerdict && isAllowedVoting) || 
				playerData.verdict !== null
			)
		) {
			/* Show verdict */
			showVerdict = true;
		} else if (index === popupsOrder.length - 2) {
			/* Verdict not allowed yet */
			newPopupIndex = popupsOrder.length - 3;
			popupData = {show: true, type: popupsOrder[popupsOrder.length - 3]};
		}

		
		if (index === popupsOrder.length - 1) {
			if (playerData.verdict !== null) {
				/** sentencing */
				popupData = {show: true, type: 'sentencing'};
				showAnimation = checkIfShowAnimation(playerData, 'sentencing');

				if (!playerData.courtRoomComplete.includes('voting')) completedRoomId = 'voting';
			} else {
				/** go back if no verdict has been chosen */
				prevPopupIndex = (index - 2);
				newPopupIndex = popupsOrder.length - 2;
				showVerdict = true;
			}
		}
	}

	/* Return result */
	return {
		prevPopupIndex,
		newPopupIndex,
		popupData,
		showAnimation,
		showTip,
		showLegalFramework,
		showVerdict,
		completedRoomId
	};
};

/**
 * checks with animation and background should be played
 * has two states static or animation based on going backwards or the play aldready has completed the current page
 */
function getRoomAndAnimation(
	prevPopupIndex, 
	popupIndex, 
	isAnimation, 
	isVoting, 
	popup, 
	showLegalFrameWork,
	selectedTabId, 
	prevTabId
) {
	let room = '';
	let flowAnimation = '';

	/* Rooms to be completed before voting is allowed */
	const completeBeforeVoting = courtRoomOrder.slice(1).slice(0, -1); // remove head and tail

	if (isVoting) {
		/** voting classes */
		// if going forwards play animation
		if (prevPopupIndex + 1 === popupIndex) {
			room = 'votingRoom';		
			/** display animation */
			if (isAnimation) {
				// eslint-disable-next-line max-len
				if (popup.type === 'talkToJudge') flowAnimation = `animation-talkToJudge${popupData[popup.type].tabs ? '-' + selectedTabId : ''}`;
				if (showLegalFrameWork) {
					if (popup.type === 'empty') flowAnimation = 'animation-voting2';
				} else {
					if (popup.type === 'empty') flowAnimation = 'animation-voting';
				}
				
			
			} else {
			/** display static */
				// eslint-disable-next-line max-len
				if (popup.type === 'talkToJudge') flowAnimation = `static-talkToJudge${popupData[popup.type].tabs ? '-' + selectedTabId : ''}`;
				if (showLegalFrameWork) {
					if (popup.type === 'empty') flowAnimation = 'static-voting2';
				} else {
					if (popup.type === 'empty') flowAnimation = 'static-voting';
				}
			}
		} else {
			// if going backward dont play animation
			room = 'votingRoom';
			/** display static */
			// eslint-disable-next-line max-len
			if (popup.type === 'talkToJudge') flowAnimation = `static-talkToJudge${popupData[popup.type].tabs ? '-' + selectedTabId : ''}`;
			if (showLegalFrameWork) {
				if (popup.type === 'empty') flowAnimation = 'static-voting2';
			} else {
				if (popup.type === 'empty') flowAnimation = 'static-voting';
			}
		}
	} else {
		if (prevPopupIndex + 1 === popupIndex) {
			/** court-room classes */
			room = 'courtRoom';
			if (isAnimation) {
			/** display animation */
			/** dont play when going backways in tabid */
				if (selectedTabId === prevTabId - 1 && popupData[popup.type].tabs) {
					if (completeBeforeVoting.includes(popup.type) || popup.type === 'sentencing') {
						// eslint-disable-next-line max-len
						flowAnimation = `static-${popup.type}${popupData[popup.type].tabs ? '-' + selectedTabId : ''}`;
					}
				} else {
					if (completeBeforeVoting.includes(popup.type) || popup.type === 'sentencing') {
						// eslint-disable-next-line max-len
						flowAnimation = `animation-${popup.type}${popupData[popup.type].tabs ? '-' + selectedTabId : ''}`;
					}
				}
			} else {

				/** display static image */
				if (completeBeforeVoting.includes(popup.type) || popup.type === 'sentencing') {
					flowAnimation = `static-${popup.type}${popupData[popup.type].tabs ? '-' + selectedTabId : ''}`;
				}
			}
		} else {
			/** court-room classes */
			room = 'courtRoom';
			/** display static image */
			if (completeBeforeVoting.includes(popup.type) || popup.type === 'sentencing') {
				flowAnimation = `static-${popup.type}${popupData[popup.type].tabs ? '-' + selectedTabId : ''}`;
			}
		}
	}

	return {
		room,
		flowAnimation
	};
};

export {
	checkIfShowAnimation,
	getNavData,
	getRoomAndAnimation,
	getTextAndQuestionData
};