import React from 'react';
import CookieConsent from './cookie-consent';
import PropTypes from 'prop-types';

class CookieConsentController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCookieConsent: false,
			showMore: false,
		};
	}

	/**
	 * Opens consent dialog
	 */
	openConsent = () => {
		this.setState({showCookieConsent: true, showMore: false});
	}

	/**
	 * Accepts or declines cookies depending on the given boolean
	 * @param {bool} cookiesAccepted 
	 */
	handleClosePopup = (cookiesAccepted) => {
		this.props.setCookieConsent(cookiesAccepted).then((response) => {
			if (response.status === 'ok') {
				this.setState({
					showCookieConsent: false,
				});
			}
		});
	}

	toggleReadMore = () => {
		this.setState({
			showMore: true,
		});
	}

	
	/* Render component */
	render = () => {
		return <CookieConsent 
			handleClosePopup = {this.handleClosePopup}
			showCookieConsent = {this.state.showCookieConsent}
			openConsent = {this.openConsent}
			cookieConsentState = {this.props.cookieConsentState}
			triggerFirstTimeCookieConsent = {this.props.triggerFirstTimeCookieConsent}
			showMore = {this.state.showMore}
			toggleReadMore = {this.toggleReadMore}
		/>;
	}
}

CookieConsentController.propTypes = {
	cookieConsentState: PropTypes.bool.isRequired,
	setCookieConsent: PropTypes.func.isRequired,
	triggerFirstTimeCookieConsent: PropTypes.bool.isRequired,
};

export default CookieConsentController;