import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './image-loader.scss';


const ImageLoader = ({imagesDataInitial, imagesData, videoData, preloadCallback}) => {
	const [initialLoadIsComplete, setInitialLoadIsComplete] = useState(false);

	const [preloadedSuccessCount, setImagesPreloadedSuccessCount] = useState(0);
	const [preloadedErrorCount, setImagesPreloadedErrorCount] = useState(0);
	/**
	 * Preload images
	 */
	const preloadData = (status) => {
		/* Increase success / error counters */
		if (status === 'ok') {
			setImagesPreloadedSuccessCount(preloadedSuccessCount + 1);
		} else {
			setImagesPreloadedErrorCount(preloadedErrorCount + 1);
		}		
	};

	useEffect(()=>{
		/* Check if all images have been preloaded */
		if ((preloadedSuccessCount + preloadedErrorCount) >= imagesDataInitial.length) {
			preloadCallback();

			/* Start loading the rest of the images & videos */
			setInitialLoadIsComplete(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preloadedSuccessCount, preloadedErrorCount]);



	return (
		<div className="ImageLoader">
			{imagesDataInitial.map((imageData, index) => {
				let imgSrc = require('assets/images/' + imageData).default;
				return <img key={index} src={imgSrc} alt="preload" 
					onLoad={() => {
						return preloadCallback ? preloadData('ok') : null;
					}}
					onError={() => {
						return preloadCallback ? preloadData('error') : null;
					}}/>;
			})}
			{initialLoadIsComplete && imagesData.map((imageData, index) => {
				let imgSrc = require('assets/images/' + imageData).default;
				return <img key={index} src={imgSrc} alt="preload" 
					onLoad={() => {
						return preloadCallback ? preloadData('ok') : null;
					}}
					onError={() => {
						return preloadCallback ? preloadData('error') : null;
					}}/>;
			})}

			{(initialLoadIsComplete && videoData) && videoData.map((video, index) => {
				return <video key={index} width={'0%'} height={'0%'} >
					<source src={video} 
						type="video/mp4"></source>
				</video>;
			})}
		</div>
	);
};

ImageLoader.propTypes = {
	imagesDataInitial: PropTypes.array.isRequired,
	imagesData: PropTypes.array.isRequired,
	videoData: PropTypes.array,
	preloadCallback: PropTypes.func,
};
export default ImageLoader;