import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts, verdictUiTexts} from 'data/ui-texts';
import './popup-verdict.scss';
import renderMarkdown from 'helpers/text-helper.js';


const PopupVerdict = (props) => {
	const {
		showPopup, 
		updatePlayerData, 
		updateStatsDoc, 
		playerData, 
		getStatsCollection, 
		showButtonAnimation
	} = props;

	/** updates the playerdata with the given verdict */
	const giveVerdict = (verdict) => {
		updatePlayerData({verdict}).then(()=>{
			updateStatsDoc({verdict});
		});
		
	};
	/** options and the players selected option */
	const [selectedOption, setSelectedOption] = useState(0);
	const options = [1, 2, 3, 4, 5];
	
	/** onmount fetch results */
	useEffect(()=>{
		getVerdict();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/**
	 * fetchs verdicts already in DB and calculates % for each option
	 */
	const getVerdict = () => {
		getStatsCollection().then((response) =>{
			if (response.status === 'ok') {
				let verdicts = [];
				let counter = [];
				response.stats.forEach((stat) => {
					verdicts.push(stat.verdict);
				});
					
				options.forEach((option) => {
					counter.push(((verdicts.reduce((counter, obj) => {
						// eslint-disable-next-line no-param-reassign
						return obj === option ? counter += 1 : counter;
					}, 0) / verdicts.length ) * 100).toFixed(0));
				}); 
				updatePlayerData({otherVerdict: counter});
			}
		},
		(error) => {
			console.error(error);
		});
	};

	return (
		<div className={'PopupVerdict ' + (showPopup ? 'show' : '')}>
			{JSON.parse(JSON.stringify(playerData.verdict)) ? 
				<div className='PopupVerdict-content'> 
					<div className="PopupVerdict-title"><span>{verdictUiTexts.title}</span></div>
					<div className='PopupVerdict-line'></div>
					{options.map((option) => {
						return <div className='PopupVerdict-options' key={option}>
							<div className={`PopupVerdict-optionText 
							${'option-' + option}`}>
								<span>{verdictUiTexts['verdict' + option]}</span>
							</div>
							<div className={`PopupVerdict-optionOthers ${'option-' + option} 
						${option === playerData.verdict ? 'current' : ''}`}>
								{playerData.otherVerdict && <span>
									{playerData.otherVerdict.length !== 0 ?
										playerData.otherVerdict[option - 1] + '%' : '...'}
								</span>}
							</div>
						</div>;
					})}
					<div className='PopupVerdict-text2'>
						<span>{renderMarkdown(verdictUiTexts.verdicttext)}</span>
					</div>

				</div> :
				<div className='PopupVerdict-content'>
					<div className="PopupVerdict-title"><span>{verdictUiTexts.popupVerdictText}</span></div>
					<div className='PopupVerdict-line'></div>
					{options.map((option) => {
						return <div className='PopupVerdict-options' key={option}>
							<div className={`PopupVerdict-optionText 
							${'option-' + option}`} >
								<span>{verdictUiTexts['verdict' + option]}</span>
							</div>
							<div className={`PopupVerdict-option ${'option-' + option} 
						${option === selectedOption ? 'current' : ''}`} 
							onClick={() => {setSelectedOption(option);}}>
							</div>
						</div>;
					})}
					<div className={'PopupVerdict-btn ' + (showButtonAnimation ? 'blink' : '')} 
						onClick={() => {
							if (selectedOption !== 0) giveVerdict(selectedOption);
						}}><span>{generalUiTexts.choose}</span></div>
				</div>}
		</div>
	);
};

PopupVerdict.propTypes = {
	showPopup: PropTypes.bool.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	updateStatsDoc: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
	getStatsCollection: PropTypes.func.isRequired,
	showButtonAnimation: PropTypes.bool.isRequired,
};

export default PopupVerdict;