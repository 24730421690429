import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {courtRoomUiTexts} from 'data/ui-texts';
import {popupData, courtRoomOrder, votingRoomOrder } from 'data/popup-data';
import Button from 'components/button/button';
import Popup from 'components/popup/popup';
import PopupVerdict from 'components/popup-verdict/popup-verdict';
import LegalFramework from 'components/legal-framework/legal-framework';
import './court-room.scss';

const CourtRoom = (props) => {
	const {
		showLegalFrameWork,
		showPopupTip,
		showPopupVerdict,
		showButtonAnimation,
		textIndex,
		popupIndex,
		selectedTabId,
		room,
		flowAnimation,
		playerData, 
		popup,
		goToTextIndex,
		updatePlayerData, 
		updateStatsDoc, 
		getStatsCollection, 
		playClick,
		completeRoom,
		setPopup,
		setPopupIndex,
		setSelectedTabId,
		setShowLegalFrameWork,
		setShowButtonAnimation,
		handleNavigate,
		nextButtonClick
	} = props;

	/* Player can continue */
	const canContinue = (playerData.isLegalFrameworkComplete ? true : false);

	/* Get order of popups */
	let popupsOrder = JSON.parse(JSON.stringify(courtRoomOrder.concat(votingRoomOrder)));

	/* Get completed rooms */	
	let listOfCompletedRooms = JSON.parse(JSON.stringify(playerData.courtRoomComplete));

	/**
	 * Component did mount
	 */
	useEffect(()=>{
		/* Show welcome popup */
		setPopupIndex(1, 0);
		setSelectedTabId(1, 0);
		setPopup({show: true, type: 'welcome'});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/* Check if next btn is disabled */
	let isDisabledButton = false;
	if (!listOfCompletedRooms.includes(popup.type) && popup.type !== 'empty') {
		if (
			(popupData[popup.type].texts.length > 1) || 
			(
				popupData[popup.type].tabs !== null && 
				popupData[popup.type].texts.filter((text) =>{
					return text.tabId === selectedTabId;
				}).length !== textIndex + 1
			)
		) {
			isDisabledButton = true;
		}
	}

	/** scroll current index in the middle of the screen */
	const currentobjDiv = document.getElementsByClassName('current');
	if (currentobjDiv[0]) currentobjDiv[0].scrollIntoView({behavior: 'smooth', block: 'end', inline: 'center'});

	return (
		<div className={'CourtRoom'}>
			{/* Background */}
			<div className={'CourtRoom-background ' + room + ' ' + (flowAnimation ? flowAnimation : '')}>
				{flowAnimation.includes('animation') && <div className={'CourtRoom-background-paper'}/>}
				{flowAnimation.includes('animation') && <div className={'CourtRoom-background-clock'}/>}
				{(room !== 'votingRoom') && <div className={'CourtRoom-names'}>
					<div className={'CourtRoom-names-lay'}><span>{courtRoomUiTexts.layJudges}</span></div>
					<div className={'CourtRoom-names-legal'}><span>{courtRoomUiTexts.legalJudge}</span></div>
					<div className={'CourtRoom-names-layYou'}><span>{courtRoomUiTexts.layJudgesYou}</span></div>
				</div>}
			</div>
				
			{/* Popup */}
			{!showPopupVerdict && <Popup
				flowAnimation={flowAnimation ? flowAnimation : ''}				
				onBack={() => {
					handleNavigate(popupsOrder.indexOf('voting'));
					setShowLegalFrameWork(false);
				}}
				popup={popup}
				playerData={playerData}
				updatePlayerData={updatePlayerData}
				completeRoom= {completeRoom}
				listOfComplete={listOfCompletedRooms}
				selectedTabId={selectedTabId}
				setSelectedTabId={setSelectedTabId}
				popupIndex={popupIndex}
				textIndex={textIndex}
				goToTextIndex={goToTextIndex}
				showButtonAnimation={showButtonAnimation}
				setShowButtonAnimation={setShowButtonAnimation}
				handleNavigate={handleNavigate}
				nextButtonClick={nextButtonClick}
				playClick={playClick}
			/>}

			{/* Verdict */}
			{showPopupVerdict && <PopupVerdict
				showPopup={showPopupVerdict}			
				playerData={playerData}
				updatePlayerData={updatePlayerData}
				updateStatsDoc={updateStatsDoc}
				getStatsCollection={getStatsCollection}
				showButtonAnimation={showButtonAnimation}
			/>}

			{/* Tip */}
			{showPopupTip && <div className='CourtRoom-tip'>{courtRoomUiTexts.tip}</div>}

			{/* Legal framework */}
			{showLegalFrameWork && 
				<>
					<LegalFramework 
						classnames={flowAnimation} 
						canContinue={canContinue}
						showButtonAnimation={showButtonAnimation}
						textIndex={textIndex}
						goToTextIndex={goToTextIndex}
						updatePlayerData={updatePlayerData}
					/>
					<Button 
						classes={['next showLegalFrameWork', (!canContinue ? 'disabled' : '')]} 
						onClick={() => {
							if (!canContinue) {
								setShowButtonAnimation(true);
							}
							setShowLegalFrameWork(false);
							playClick();
							handleNavigate(popupIndex + 1);
						}}
					/>
				</>
			}

			{/* Prev btn */}
			<Button 
				classes={['prev']} 
				onClick={() => {handleNavigate(popupIndex - 1); playClick();}}
			/>

			{/* Next btn */}
			{!showLegalFrameWork && !showPopupVerdict && <Button 
				classes={['next', `${isDisabledButton ? 'disabled' : ''}`]} 
				onClick={() => {
					if (isDisabledButton) {
						setShowButtonAnimation(true);
						return;
					}
					playClick();
					handleNavigate(popupIndex + 1);
				}}
			/>}

			{/* Next btn verdict */}
			{showPopupVerdict && <Button 
				classes={['next', `${playerData.verdict === null ? 'disabled' : ''}`]} 
				onClick={() => {
					if (playerData.verdict === null) {
						setShowButtonAnimation(true);
						return;
					}
					handleNavigate(popupIndex + 1);
					playClick();
				}}
			/>}
		</div>		
	);
};

CourtRoom.propTypes = {
	showPopupVerdict: PropTypes.bool.isRequired,
	showPopupTip: PropTypes.bool.isRequired,
	showLegalFrameWork: PropTypes.bool.isRequired,
	showButtonAnimation: PropTypes.bool.isRequired,
	textIndex: PropTypes.number.isRequired,
	playerData: PropTypes.object.isRequired,
	popup: PropTypes.object,
	popupIndex: PropTypes.number.isRequired,
	selectedTabId: PropTypes.number.isRequired,
	room: PropTypes.string.isRequired,
	flowAnimation: PropTypes.string,
	updatePlayerData: PropTypes.func.isRequired,
	updateStatsDoc: PropTypes.func.isRequired,
	getStatsCollection: PropTypes.func.isRequired,
	playClick:PropTypes.func.isRequired,
	completeRoom:PropTypes.func.isRequired,
	goToTextIndex: PropTypes.func.isRequired,
	setPopup: PropTypes.func.isRequired,
	setPopupIndex: PropTypes.func.isRequired,
	setSelectedTabId: PropTypes.func.isRequired,
	setShowLegalFrameWork: PropTypes.func.isRequired,
	setShowButtonAnimation: PropTypes.func.isRequired,
	handleNavigate: PropTypes.func.isRequired,
	nextButtonClick: PropTypes.func.isRequired,
};

export default CourtRoom;