import React from 'react';
import PropTypes from 'prop-types';
import './orientation.scss';

const Orientation = ({classes, src}) => {
	/* Classes */
	let className = '';
	if (classes && classes.length > 0) {
		classes.forEach((c) => {className = className + ' ' + c;});
	}
	let newSrc = require('assets/images/' + src).default;
	return <div className={className}>
		<img src={newSrc}  alt={'orientation'} />
		<span>Spillet virker ikke i vertikalt format. Vend telefonen for at spille.</span>
	</div>;

};



Orientation.propTypes = {
	classes: PropTypes.array.isRequired,
	src: PropTypes.string.isRequired,
};


export default Orientation;