import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {preCourtUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import Carousel, {CarouselItem} from 'components/carousel/carousel';
import renderMarkdown from 'helpers/text-helper';
import './pre-court.scss';

const PreCourt = (props) => {
	let	{
		showButtonAnimation,
		textIndex,
		playerData,
		updatePlayerData,
		goToPage,
		goToTextIndex,
		playClick,
		setShowButtonAnimation
	} = props;

	/* Players answer */
	const [answer, setAnswer] = useState(null);

	/* Available texts */
	let texts = [preCourtUiTexts.preCourt.questions[0].question, preCourtUiTexts.preCourt.questions[0].answers[answer]];
	
	/**
	 * checks if the player has answered the question and sets the room has complete
	 *  if the answer is 'Nej'
	 */
	useEffect(()=>{
		if (answer === 0) updatePlayerData({isPreCourtComplete: true});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answer]);

	/**
	 * Component did mount
	 */
	useEffect(()=>{
		/* Check if the player has already completed the room */
		if (playerData.isPreCourtComplete) {
			setAnswer(0);
		} else {
			updatePlayerData({preCourtAnswer: null});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Selects the anwser for the player and to go the text answer text
	 * @param {int} option 
	 */
	const selectAnswer = (option) => {
		setAnswer(option);
		updatePlayerData({preCourtAnswer: option}).then(() => {
			goToTextIndex(1);
		});
	};

	return (
		<div className={'PreCourt'}>
			{(answer === null) &&
			<div className={'PreCourt-body'}>
				<div className='PreCourt-textbox'>
					<div className={'PreCourt-text'}>
						{renderMarkdown(preCourtUiTexts.preCourt.questions[0].question)}
					</div>
					<div className={'PreCourt-options'} > 
						<div className={'PreCourt-option1 ' + (showButtonAnimation ? 'blink' : '')} 
							onClick={()=>{selectAnswer(1);}}>
							<span>{renderMarkdown(preCourtUiTexts.preCourt.questions[0].options[0])}</span></div>
						<div className={'PreCourt-option2 ' + (showButtonAnimation ? 'blink' : '')} 
							onClick={()=>{selectAnswer(0);}}>
							<span>{renderMarkdown(preCourtUiTexts.preCourt.questions[0].options[1])}</span>
						</div>
					</div>
				</div>
			</div>}

			{(answer !== null) &&
			<div className={'PreCourt-body'}>
				<div className='PreCourt-textbox'>
					<Carousel goToTextIndex={goToTextIndex} activeIndex={textIndex} 
						amountOfTexts={texts.length}
						isNextText={showButtonAnimation}>
						{texts.map((text, index) => {
							return <CarouselItem key={index}>
								<div className={'PreCourt-text'}>{text ? renderMarkdown(text) : ''}</div>
							</CarouselItem>;
						})}
					</Carousel>
				</div>
			</div>}

			<Button classes={['prev']} onClick={() => {playClick();goToPage('arrival');}} />

			<Button classes={['next ' + ((answer === null) ? 'disabled' : '')
				+ (answer === 1 ? 'disabled' : '')]} onClick={() => {
				if (answer === null || answer === 1) {
					setShowButtonAnimation(true);
					return;
				}
				playClick();
				goToPage('court-room');
			}} />
		</div>
	);
};

PreCourt.propTypes = {
	showButtonAnimation: PropTypes.bool.isRequired,
	goToPage: PropTypes.func.isRequired,
	textIndex: PropTypes.number.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	playClick: PropTypes.func.isRequired,
	goToTextIndex: PropTypes.func.isRequired,
	setShowButtonAnimation: PropTypes.func.isRequired,
};

export default PreCourt;