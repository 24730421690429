const generalUiTexts = {
	goBack: 'Tilbage',
	choose: 'Vælg',
	chosen: 'Valgt',
};

// const cookieUiTexts = {
// 	text: 'Vi bruger cookies til at forbedre din spiloplevelse og for at hente spilrelateret data, som er tilknyttet hvert enkelt spilgang. Ved at klikke på \'OK\' accepterer du vores brug af cookies. Vi gemmer cookies i et år, og de spilrelateret data er dine svar på spørgsmål, din dom og dit progress i spillet.',
// 	more: 'Læs mere',
// 	btn: 'Ok',
// 	
// };

const cookieUiTexts = {
	text: 'Vi vil gerne registrere oplysninger om, dine svar på spørgsmål, din dom og dit progression i spillet. </br>'+
	'Det gør vi med en cookie ved at hente spilrelateret data, som er tilknyttet hvert enkelt spilgang. </br>'+
	'Spillet fungerer ikke uden denne cookie.  </br>'+
	'Tryk Ja for at acceptere denne cookie.',
	bigtext: 'Vi vil gerne registrere oplysninger om, dine svar på spørgsmål, din dom og dit progression i spillet.</br>'+
	'Det gør vi med en cookie ved at hente spilrelateret data, som er tilknyttet hvert enkelt spilgang. </br>'+
	'Spillet fungerer ikke uden denne cookie. </br> </br>'+
	
	'Cookie’erne bliver gemt i et år.</br> </br>'+
	
	'Vi indsamler ikke andre oplysninger, og vi deler ikke oplysningerne om, hvor langt du er nået i spillet med andre.</br> </br>'+
	
	'Tryk på Ja for at acceptere denne cookie.</br> </br>'+
	
	'Du kan altid trække jeres ”ja” tilbage. Det kan I gøre ved at trykke på den lille cookieknap i venstre hjørne. Dette vil resultere i at spillet bliver nulstillet.',
	consentButtons: {ok: 'Ja', cancel: 'Nej'},
	readMore: 'Læs mere.',
	popupText: 'For at starte et spil skal du accepterer brugen af cookies, ved at klikke på den lille cookieknap i venstre hjørne.',
};

const introUiTexts = {
	title: 'Domsmandsspillet',
	subtitle: 'Har du styr på skyld og straf?',
	text: `I Domsmandsspillet er du indkaldt som domsmand i en straffesag i byretten. En domsmand er en almindelig borger, der sidder med i retssager og sammen med den juridiske dommer vurderer, om en tiltalt er skyldig eller ej. Domsmænd er også med til at beslutte straffen. Det gør de på befolkningens vegne for at medvirke til at sikre, at en retssag foregår, som den skal. Som domsmand er det tanken, at man med åbent sind og en kritisk holdning til det, som præsenteres, er med til at afgøre sagen i retten. Er du klar?`,
	reset: 'Reset',
	start: 'Start',
	availabilityStatement: 'Tilgængelighedserklæring'
};

const arrivalUiTexts = {
	title: 'Dommer',
	texts: ['Du er blevet indkaldt som domsmand i en straffesag.'],
	video: require('assets/videos/city-court-with-birds.gif').default,	
}
const preCourtUiTexts = {
	title: 'Dommer',
	preCourt: {
		texts: [],
		questions: [
			{
				question: '<strong>Dommer </strong> <br>Godmorgen og velkommen i byretten. Vi er to domsmænd og mig som juridisk dommer til at behandle sagen i dag. Inden vi går ind i retssalen, skal vi sikre os, at ingen af os kender de involverede og dermed er inhabile. Den tiltalte hedder Christina Jensen, og vidnet hedder Margrethe Andersen. Er det navne, som I genkender fra jeres private omgangskreds eller arbejde?',
				options: ['Ja', 'Nej'],
				answers: [
					'<strong>Dommer</strong><br> Tak for det. Lad os gå ind i retssalen og komme igang med sagen.', 
					`<strong>Dommer</strong><br> Det er vigtigt, at man som domsmand ikke er personligt indblandet i - eller påvirket af
						- sagen. Hvis du fx er i familie med, eller har en nær relation til den tiltalte, et vidne, anklageren, forsvareren eller andre personer, der
						er involveret i sagen, så er du inhabil og kan ikke deltage i sagen. Tak for i dag.`
				]
			},
		],
		audio: null,
	},
}

const courtRoomUiTexts = {
	tip: 'Du skal se alle sagens trin, inden du kan gå til votering.',
	legalFramework: {
		texts: [
			'<strong>Dommer</strong><br> Nu har vi hørt sagen. Som dommer og domsmænd skal vi kun forholde jer til de beviser, som I har hørt og set her i retssalen. I skal føle jer overbeviste om, at tiltalte er skyldig uden for enhver rimelig begrundet tvivl.',
			'<strong>Dommer</strong><br> Hun er tiltalt for at have delt oplysninger om strafbare forhold uden samtykke. Sådan en videregivelse må efter loven kun ske for at varetage offentlige eller private interesser, herunder hensynet til tiltaltes egne interesser, der klart overstiger hensynet til de interesser, som begrunder hemmeligholdelse.',
			'<strong>Dommer</strong><br> Tiltalte har erkendt at have delt billederne. Spørgsmålet er, om hensynet til hendes interesse i deling klart overstiger hensynet til de interesser, der begrunder hemmeligholdelse.',
			'<strong>Dommer</strong><br> Tiltalte har forklaret, at det vigtigste for hende var at finde gerningsmanden og beskytte andre børn imod krænkelser. Hun mente ikke, at politiet havde ressourcer til at tage sig af sagen.',
			'<strong>Dommer</strong><br> Heroverfor står, at der er tale om videregivelse af personoplysninger om strafbare forhold på en åben Facebookprofil og dermed til et betydeligt antal personer.',
			'<strong>Dommer</strong><br> Det er vores opgave at afveje disse modsatrettede hensyn og beslutte, om hun er skyldig eller ej.',
			'<strong>Dommer</strong><br> Hvis vi vurderer, at tiltalte er skyldig, skal vi desuden tage stilling til, hvilken straf hun skal have. Men det skal vi først tage stilling til, hvis vi finder tiltalte skyldig.',
			'<strong>Dommer</strong><br> Afgørelserne træffes ved flertal. Jeg vil gerne først høre, hvad den anden domsmand tænker om sagen.',
		],
	},
	layJudges: 'DOMSMAND',
	legalJudge: 'JURIDISK DOMMER',
	layJudgesYou:'DOMSMAND (DIG)',
};

const votingUiTexts = {
	talkToLayJudge: 'TAL MED DEN JURIDISKE DOMMER',
	talkToJudge: 'TAL MED DEN ANDEN LÆGDOMMER',
};

const verdictUiTexts = {
	title: 'Sådan har andre spillere valgt:',
	verdict1: 'Bøde på 5.000 kr.',
	verdict2:'Bøde på 20.000 kr.',
	verdict3:'Betinget fængsel i 30 dage',
	verdict4:'Fængsel i 6 måneder',
	verdict5: 'Fængsel i 1 år',
	verdicttext: 'Sagen er inspireret af en rigtig retssag, som blev afgjort sådan her. <br/> Gå videre for at se, hvad der blev dømt i den rigtige sag.',
	popupVerdictText: 'Hvad ville du dømme?',
};

const departureUiTexts = {
	title: 'Dommer',
	text: 'Tak for hjælpen i dag.',
};

const outroUiTexts = {
	outroLabelOne: 'Dig',
	outroLabelTwo: 'Andre',
	outroLabelThree: 'Virkeligt',
	denmarkCourts: 'Om Danmarks Domstole',
	outtroBecomeJudge: 'Om at være domsmand',
	Domsdatabasen: 'Domsdatabasen',
	text: 'Sagen, som du var med til at dømme i, er fiktion, men den er inspireret af en rigtig retssag. Du kan se den og andre sager i Danmarks Domstoles domsdatabase.',
};


const statsUiTexts = {
	loginTitle: 'Statistik',
	email: 'e-mail',
	password: 'password',
	login: 'Log ind',
	logout: 'Log ud',
	unknownFejl: 'Fejl: ukendt fejl',
	someFieldsMissing: 'Fejl: Husk at udfylde alle felter',
	invalidEmail: 'Fejl: Ugyldig e-mail',
	invalidPassword: 'Fejl: Forkert password',
	userNotFound: 'Fejl: Ukendt bruger',
	statsTitle: 'Statistik for Domsmandsspillet',
	gamesStarted: 'Antal spil startet',
	gamesFinished: 'Antal spil afsluttet'
};

module.exports = {
	generalUiTexts,
	cookieUiTexts,
	introUiTexts,
	arrivalUiTexts,
	courtRoomUiTexts,
	votingUiTexts,
	verdictUiTexts,
	departureUiTexts,
	outroUiTexts,
	preCourtUiTexts,
	statsUiTexts
};